import React from "react";
import { Cat,Dog,Breakfast,Longterm,HouseKey,Userprofile,CleaningSprayAction,Smoking,CampFire,Stairs,ManStaff } from "../../Amenities/Services.";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";

const SERVICES_AMENITIES = [
    { id: 'cat', Icon: Cat },
    { id: 'dog', Icon: Dog },
    { id: 'breakfast', Icon: Breakfast },
    { id: 'longterm', Icon: Longterm },
    { id: 'housekey', Icon: HouseKey },
    { id: 'userprofile', Icon: Userprofile },
    { id: 'cleaningspray', Icon: CleaningSprayAction },
    { id: 'smoking', Icon: Smoking },
    { id: 'campfire', Icon: CampFire },
    { id: 'stairs', Icon: Stairs },
    { id: 'manstaff', Icon: ManStaff }
  ];

  const ServicesAmenities = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
      const currentAmenities = metaDetails.amenities["Services"] || [];
      const isSelected = currentAmenities.includes(amenityId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          "Services": isSelected
            ? currentAmenities.filter(item => item !== amenityId)
            : [...currentAmenities, amenityId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {SERVICES_AMENITIES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities["Services"]?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleAmenity(id)} />
            ) : (
              <PlusIcon onClick={() => toggleAmenity(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default ServicesAmenities;  