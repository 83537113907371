export const formatNumber = (value, options = {}) => {
    const {
      minDecimals = 2,
      maxDecimals = 6,
      currency = false,
      compact = false
    } = options;
  
    if (!value && value !== 0) return '-';
    
    const num = parseFloat(value);
    
    // Handle very small numbers
    if (Math.abs(num) < 0.000001 && num !== 0) {
      return num.toExponential(4);
    }
  
    // Handle regular numbers
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: maxDecimals,
      notation: compact ? 'compact' : 'standard',
      style: currency ? 'currency' : 'decimal',
      currency: 'USD'
    });
  
    return formatter.format(num);
  };