import {
    SelectionGroup,
    SelectionItem,
  } from "../../Components/Selection/Selection";
  import {
    FilterIconRent,
    CheckMarkPurple,
    PlusIcon,
  } from "../../AssetComponents/Images";
  import { PurpleButton } from "../../Components/Buttons/PurpleButton";
  import { useState, useEffect } from "react";
  import { useLocation } from "react-router-dom";
  import { useDispatch } from "react-redux";
  // import { updateToken } from "../../Components/functions/Functions";
  import { useSelector } from "react-redux";
  import Modal from "react-responsive-modal";
  import { CustomChart } from "../../Components/Chart/CustomChart";
  import RangeSlider from "react-range-slider-input";
  import "react-range-slider-input/dist/style.css";
  import {
    setPriceRange,
    setAmenities,
    setPropertyType,
    setInstantBook,
    setAllowPets,
  } from "../../ReduxSlices/SearchSlice";
  import { BlackButton } from "../../Components/Buttons/BlackButton";
  import { Toggle } from "../../Components/Toggle/Toggle";
  import {
    BathHanger,
    Bathub,
    HairDryer,
    Heating,
    HotWater,
    Shower,
    SoapDrop,
    ToiletSeat,
  } from "../../Components/Amenities/Bathroom";
  import {
    Balcony,
    Park,
    SwimmingPool,
    ValleyView,
  } from "../../Components/Amenities/View";
  import {
    Bed,
    BedDouble,
    BedroomHanger,
    DresserDrawer,
    DressingMirror,
    SafeBox,
    SofaDouble,
    TrowelBrush,
    WashingMachine,
  } from "../../Components/Amenities/Bedroom";
  import { GamePad } from "../../Components/Amenities/Entertainment";
  import {
    Alarm,
    CCTV,
    FireExtinguisher,
    MedicalCase,
    TvStand,
  } from "../../Components/Amenities/HomeSafety";
  import {
    LaptopTable,
    Wifi,
    WorkJob,
  } from "../../Components/Amenities/InternetAndOffice";
  import {
    Blender,
    CoffeeMachine,
    Dishes,
    DishWasher,
    ForkKnife,
    Fridge,
    Induction,
    Kitchen,
    PlateFork,
    SaltPepper,
    Stove,
    TableChair,
    Trash,
    WineGlass,
  } from "../Amenities/Kitchen";
  import {
    BeachUmbrella,
    ChildrenSlide,
    Lounge,
    Sun,
    Swing,
  } from "../Amenities/Outdoor";
  import { Parking } from "../Amenities/Parking";
  import {
    Breakfast,
    CampFire,
    Cat,
    CleaningSprayAction,
    Dog,
    HouseKey,
    Longterm,
    ManStaff,
    Smoking,
    Stairs,
    Userprofile,
  } from "../Amenities/Services.";
  import {
    BabyBedroom,
    BookFairyTale,
    ToyCubes,
  } from "../Amenities/Family";
  
  import {
    AirConditioning,
    Beach,
    BeachView,
    BedLinenTowels,
    CableTv,
    CarbonMonoxideDetector,
    CityView,
    CleaningProducts,
    CoffeeMaker,
    ComplimentarySoapShampooConditioner,
    CookingBasics,
    CookwareKitchenUtensils,
    CrockeryCutlery,
    DiningTable,
    DryingRack,
    Elevator,
    Essentials,
    FamilyKidsFriendly,
    FirstAidKit,
    FreeParkingWithGarage,
    Gym,
    Hangers,
    IronIroningBoard,
    Kettle,
    Marina,
    MarinaView,
    Microwave,
    NoParties,
    Oven,
    PlayGround,
    Reception,
    Refrigerator,
    RoomDarkeningShades,
    Seaview,
    SmartTv,
    SmokeDetectors,
    SmokingNotAllowed,
    StreamingServiceSuchAsNetflix,
    Toaster,
    Toilet,
    TrashCans,
    Wardrobe,
    WineGlasses,
    WirelessInternet,
  } from "../Amenities/Others";
  import { api } from "../functions/Api";
  import { Appliance, Fan } from "../Amenities/HeatingandCooling";
  import { amenityCategories } from "./FilterConstants";
import { PriceRangeSection } from "./FilterPriceRangeSection";


export const AmenityIcons = ({ item }) => {
    const iconComponents = {
      // Bathroom
      bathub: Bathub,
      hairdryer: HairDryer,
      shower: Shower,
      hotwater: HotWater,
      soapdrop: SoapDrop,
      heating: Heating,
      toiletseat: ToiletSeat,
      bathhanger: BathHanger,
  
      // View
      swimmingpool: SwimmingPool,
      valleyview: ValleyView,
      park: Park,
      balcony: Balcony,
  
      // Bedroom
      trowelbrush: TrowelBrush,
      hanger: BedroomHanger,
      beddouble: BedDouble,
      bed: Bed,
      dresserdrawer: DresserDrawer,
      dressingmirror: DressingMirror,
      safebox: SafeBox,
      sofadouble: SofaDouble,
      washingmachine: WashingMachine,
  
      // Entertainment
      gamepad: GamePad,
  
      // Home Safety
      medicalcase: MedicalCase,
      fireextinguisher: FireExtinguisher,
      tvstand: TvStand,
      alarm: Alarm,
      cctv: CCTV,
  
      // Internet and Office
      wifi: Wifi,
      workjob: WorkJob,
      laptoptable: LaptopTable,
  
      // Kitchen
      kitchen: Kitchen,
      forkknife: ForkKnife,
      stove: Stove,
      induction: Induction,
      dishes: Dishes,
      fridge: Fridge,
      tablechair: TableChair,
      coffeemachine: CoffeeMachine,
      blender: Blender,
      saltpepper: SaltPepper,
      dishwasher: DishWasher,
      wineglass: WineGlass,
      trash: Trash,
      platefork: PlateFork,
  
      // Outdoor
      childrenslide: ChildrenSlide,
      lounge: Lounge,
      swing: Swing,
      sun: Sun,
      beachumbrella: BeachUmbrella,
  
      // Parking
      parking: Parking,
  
      // Services
      cat: Cat,
      dog: Dog,
      breakfast: Breakfast,
      longterm: Longterm,
      housekey: HouseKey,
      userprofile: Userprofile,
      cleaningspray: CleaningSprayAction,
      smoking: Smoking,
      campfire: CampFire,
      stairs: Stairs,
      manstaff: ManStaff,
  
      // Family
      toyscubes: ToyCubes,
      fairytale: BookFairyTale,
      babybedroom: BabyBedroom,
  
      // Heating and Cooling
      appliance: Appliance,
      fan: Fan,
  
      // Others
      CookwareKitchenUtensils,
      DryingRack,
      CableTv,
      Toaster,
      Wardrobe,
      DiningTable,
      Reception,
      CityView,
      SmokeDetectors,
      MarinaView,
      Gym,
      CarbonMonoxideDetector,
      Hangers,
      TrashCans,
      WineGlasses,
      StreamingServiceSuchAsNetflix,
      CrockeryCutlery,
      Toilet,
      Oven,
      CoffeeMaker,
      ComplimentarySoapShampooConditioner,
      BeachView,
      Elevator,
      WirelessInternet,
      FreeParkingWithGarage,
      SmartTv,
      Marina,
      RoomDarkeningShades,
      IronIroningBoard,
      BedLinenTowels,
      Kettle,
      Microwave,
      AirConditioning,
      Seaview,
      Beach,
      PlayGround,
      Refrigerator,
      FamilyKidsFriendly,
      NoParties,
      Essentials,
      CleaningProducts,
      FirstAidKit,
      CookingBasics,
      SmokingNotAllowed
    };
  
    const IconComponent = iconComponents[item];
    
    if (!IconComponent) {
      return null;
    }
  
    return (
      <div>
        <IconComponent />
      </div>
    );
  };