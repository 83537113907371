import React from "react";
import { MedicalCase,FireExtinguisher, TvStand, Alarm, CCTV  } from "../../Amenities/HomeSafety";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";
const HOME_SAFETY_AMENITIES = [
    { id: 'medicalcase', Icon: MedicalCase },
    { id: 'fireextinguisher', Icon: FireExtinguisher },
    { id: 'tvstand', Icon: TvStand },
    { id: 'alarm', Icon: Alarm },
    { id: 'cctv', Icon: CCTV }
  ];
  
  const HomeSafety = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
      const currentAmenities = metaDetails.amenities["Home safety"] || [];
      const isSelected = currentAmenities.includes(amenityId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          "Home safety": isSelected
            ? currentAmenities.filter(item => item !== amenityId)
            : [...currentAmenities, amenityId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {HOME_SAFETY_AMENITIES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities["Home safety"]?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleAmenity(id)} />
            ) : (
              <PlusIcon onClick={() => toggleAmenity(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default HomeSafety;