import React from 'react';

const AmenityCategory = ({ item, currentCategory, setCurrentCategory }) => {
  return (
    <div
      onClick={() => setCurrentCategory(item)}
      className={
        currentCategory === item
          ? "bg-[#dddddd] hover:bg-[#dddddd] cursor-pointer select-none px-[14px] py-[8px] rounded-[12px] w-max border-[1px] border-[#E3E3E3]"
          : "hover:bg-[#dddddd] cursor-pointer select-none px-[14px] py-[8px] rounded-[12px] w-max border-[1px] border-[#E3E3E3]"
      }
    >
      {item}
    </div>
  );
};

export default AmenityCategory;