  import {
    FilterIconRent,
  } from "../../AssetComponents/Images";
  import { PurpleButton } from "../../Components/Buttons/PurpleButton";
  import { useState, useEffect } from "react";
  import { useLocation } from "react-router-dom";
  import { useDispatch } from "react-redux";
  // import { updateToken } from "../../Components/functions/Functions";
  import { useSelector } from "react-redux";
  import Modal from "react-responsive-modal";
  import "react-range-slider-input/dist/style.css";
  import {
    setPriceRange,
    setAmenities,
    setPropertyType,
    setInstantBook,
    setAllowPets,
  } from "../../ReduxSlices/SearchSlice";
  import { BlackButton } from "../../Components/Buttons/BlackButton";
  import { Toggle } from "../../Components/Toggle/Toggle";
  
  import { amenityCategories } from "./FilterConstants";
import { PriceRangeSection } from "./FilterPriceRangeSection";
import { FilterButton } from "./FilterButton";
import { BedroomFilter } from "./BedroomFilter";
import { BedFilter } from "./BedFilter";
import { BathroomFilter } from "./BathroomFilter";
import { PropertyTypeFilter } from "./PropertyTypeFilter";
import { BookingOptions } from "./BookingOptions";
import { AmenitiesSection } from "./AmenitiesSection";
import { FilterModal } from "./FilterModal";
import AmenityCategory from "./FilterComponents/AmenityCategory";
import BathroomAmenities from "./FilterComponents/BathroomAmenities";
import LocationFeatures from "./FilterComponents/LocationFeatures";
import BedroomAndLaundry from "./FilterComponents/BedRoomAndLaundry";
import Entertainment from "./FilterComponents/Entertainment";
import HomeSafety from "./FilterComponents/HomeSafety";
import InternetAndOffice from "./FilterComponents/InternetAndOffice";
import KitchenAndDining from "./FilterComponents/KitchenAndDining";
import OutdoorAmenities from "./FilterComponents/OutdoorAmenities";
import ParkingAndFacilities from "./FilterComponents/ParkingAndFacilities";
import ServicesAmenities from "./FilterComponents/ServicesAmenities";
import FamilyAmenities from "./FilterComponents/FamilyAmenities";
import HeatingAndCoolingAmenities from "./FilterComponents/HeatingAndCoolingAmenities";
import Others from "./FilterComponents/Other";
import BookingOptionsFilter from "./FilterComponents/Bookings";
  
  export const Filter = ({properties, onFilterChange}) => {
    const reservation_period = useSelector((state) => state.reservation.period);
    const who = useSelector((state) => state.reservation.who);
    const guests = useSelector((state) => state.reservation.guests);
    const amenities = useSelector((state) => state.search.amenities);
    const propertyType = useSelector((state) => state.search.propertyType);
  
    const [metaDetails, setMetaDetails] = useState({
      amenities: {},
    });
    
   
    
    const [openFilter, setOpenFilter] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    
    const [currentCategory, setCurrentCategory] = useState("Bathroom");
    const [sortType, setSortType] = useState('default');
    
    const range = useSelector((state) => state.search.priceRange);
    const instantBook = useSelector((state) => state.search.instantBook);
    const allowPets = useSelector((state) => state.search.allowPets);
    
    const [pinLocation, setPinLocation] = useState({
      lat: 25,
      lng: 55,
    });
  
    // Price Range Effect
    useEffect(() => {
      if (!range?.max)
        dispatch(
          setPriceRange({
            min: range?.min,
            max: 1000,
          })
        );
      if (!range?.min)
        dispatch(
          setPriceRange({
            min: 1,
            max: range?.max,
          })
        );
    }, [range]);

  
    // Clear Filter Handler
    const handleClear = () => {
      dispatch(
        setPriceRange({
          min: 1,
          max: 1000,
        })
      );
      dispatch(
        setAmenities({
          bathroom: 0,
          bedroom: 0,
          bed: 0,
          squareFeet: "",
        })
      );
      dispatch(setPropertyType(null));
      dispatch(setInstantBook(false));
      dispatch(setAllowPets(false));
      setMetaDetails({ amenities: {} });
    };
  
    // Filter Application Logic
    useEffect(() => {
      const filtered = properties.filter((prop) => {
        // Filter by location (if provided)
        const locationMatch = !location || 
          (prop.metaData?.location?.lat === location.lat && 
           prop.metaData?.location?.lng === location.lng);
    
        // Filter by guests (if provided)
        const guestsMatch = !guests || prop.short?.max_guests >= guests;
    
        // Price range filter
        const priceMatch = 
          prop.short?.price_per_day >= range.min && 
          prop.short?.price_per_day <= range.max;
  
        // Instant book filter
        const instantBookMatch = !instantBook || prop.short?.instantBook;
  
        // Pets filter
        const petsMatch = !allowPets || prop.short?.allowPets;
    
        return locationMatch && 
               guestsMatch && 
               priceMatch && 
               instantBookMatch && 
               petsMatch;
      });
    
      onFilterChange(filtered);
    }, [
      properties, 
      reservation_period, 
      who, 
      guests, 
      location, 
      pinLocation, 
      range, 
      sortType,
      instantBook,
      allowPets
    ]); 
  

  return (
    <div>
          <FilterButton onClick={() => setOpenFilter(true)} />
            <Modal
              open={openFilter}
              center
              onClose={() => setOpenFilter(false)}
              classNames={{
                modal:
                  "rounded-[8px] border-[1px] border-[#E3E3E3] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]",
              }}
            >
              <div className="w-[500px]">
                <div className="flex items-center w-full mb-[20px] gap-1">
                 <FilterIconRent/>
                  <div className="text-[20px] font-semibold">Filters</div>
                </div>
                <div className="h-[50vh] overflow-auto scrollbarwidth px-[10px]">
                  <div className="w-full">
                    <PriceRangeSection range={range} onRangeChange={(value) => dispatch(setPriceRange(value))} />
                  
                  </div>
                  <div className="grid grid-cols-2 gap-[20px] my-[16px] font-semibold">
                

                  
                  </div>
                  <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />
                  <div className="space-y-[12px]">
                    <div className="text-[18px] font-semibold">
                      Rooms and beds
                    </div>
                    <BedroomFilter amenities={amenities} dispatch={dispatch} />
                    <BedFilter amenities={amenities} dispatch={dispatch} />
                    <BathroomFilter amenities={amenities} dispatch={dispatch} />                    
                  </div>
                  <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />
                    <PropertyTypeFilter propertyType={propertyType} dispatch={dispatch} />
                  <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />
                    <BookingOptions instantBook={instantBook} allowPets={allowPets} dispatch={dispatch} />
                    <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />

                  <div className="w-full space-y-[10px]">
                   <AmenitiesSection metaDetails={metaDetails} />

                   <FilterModal/>

                    <div className="w-full space-y-[10px]">
                      <div className="w-full flex flex-wrap gap-[10px]">
                      {amenityCategories.map((item) => (
                           <AmenityCategory
                            key={item}
                            item={item}
                            currentCategory={currentCategory}
                            setCurrentCategory={setCurrentCategory}
                             />
                          ))}
                      </div>
                      <div className=" gap-[10px] p-[10px]">
                        {(currentCategory === "Bathroom" ||
                          currentCategory === "All") && (
                        <BathroomAmenities  metaDetails={metaDetails}
                        setMetaDetails={setMetaDetails}
                      />
                        )}
                        {(currentCategory === "Location features" ||
                          currentCategory === "All") && (
                         <LocationFeatures metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Bedroom and Laundry" ||
                          currentCategory === "All") && (
                        <BedroomAndLaundry metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Entertainment" ||
                          currentCategory === "All") && (
                         <Entertainment metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Home safety" ||
                          currentCategory === "All") && (
                         <HomeSafety metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Internet and office" ||
                          currentCategory === "All") && (
                         <InternetAndOffice metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Kitchen and dining" ||
                          currentCategory === "All") && (
                         <KitchenAndDining metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Outdoor" ||
                          currentCategory === "All") && (
                          <OutdoorAmenities metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Parking and facilities" ||
                          currentCategory === "All") && (
                         <ParkingAndFacilities metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Services" ||
                          currentCategory === "All") && (
                          <ServicesAmenities metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Family" ||
                          currentCategory === "All") && (
                        <FamilyAmenities metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                        {(currentCategory === "Heating and cooling" ||
                          currentCategory === "All") && (
                        <HeatingAndCoolingAmenities metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}

                        {(currentCategory === "Others" ||
                          currentCategory === "All") && (
                         <Others metaDetails={metaDetails} setMetaDetails={setMetaDetails} />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-[2px] bg-[#eeeeee] my-[12px]" />

                  <div className="space-y-[12px]">
                    <BookingOptionsFilter instantBook={instantBook} allowPets={allowPets} dispatch={dispatch} />
                   
                  </div>
                </div>
                <div className="flex items-center justify-between mt-[32px]">
                  <BlackButton text="Clear" onClick={handleClear} />
                  <PurpleButton
                    text="Apply"
                    onClick={() => setOpenFilter(false)}
                  />
                </div>

              </div>
            </Modal>
    </div>
  )
}

export default Filter