import React from 'react'

function Chart() {
  return (
    <div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.16699 13.2409L8.39283 9.01504C8.71866 8.68921 9.24616 8.68921 9.57116 9.01504L11.3553 10.7992C11.6812 11.125 12.2087 11.125 12.5337 10.7992L17.5003 5.83337" stroke="#959595" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.2773 5.83337H17.4998V8.05587" stroke="#959595" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.5003 17.4409H1.60449V2.6842" stroke="#959595" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


    </div>
  )
}

export default Chart