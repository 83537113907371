import React from "react";

const TradeDirection = ({ longShort, setLongShort }) => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <button
        onClick={() => setLongShort(0)}
        className={`py-3 px-4 rounded-lg font-medium transition-all ${
          longShort === 0
            ? "bg-green-500 text-white"
            : "bg-gray-100 text-gray-500 hover:bg-gray-200"
        }`}
      >
        Buy
      </button>
      <button
        onClick={() => setLongShort(1)}
        className={`py-3 px-4 rounded-lg font-medium transition-all ${
          longShort === 1
            ? "bg-red-500 text-white"
            : "bg-gray-100 text-gray-500 hover:bg-gray-200"
        }`}
      >
        Sell
      </button>
    </div>
  );
};

export default TradeDirection;
