import Graph from "./Components/Graph";
import GraphSelected from "./Components/GraphSelected";
import Chart from "./Components/Chart";
import ChartSelected from "./Components/ChartSelected";

export const ChartTypeToggle = ({ activeType, onToggle, setChartType }) => (
  <div className="flex space-x-2 bg-[#F6F6F6] rounded-md p-1" style={{
    border: "1px solid #E3E3E3",
    boxShadow: "0px 0px 0px 2px #0000000D, -2px -2px 6px 0px #FDFFFFCC, 2px 2px 6px 0px #BBC3CE99"
  }}>
    <button
      onClick={() => { onToggle('line'); setChartType('line'); }}
      className={`p-1.5 rounded transition-all duration-200 ${
        activeType === 'line' 
          ? 'bg-white text-blue-600' 
          : 'text-gray-600 hover:bg-gray-100'
      }`}
    >
      {activeType === 'line' ? <GraphSelected /> : <Graph />}
    </button>
    <button
      onClick={() => { onToggle('candlestick'); setChartType('candlestick'); }}
      className={`p-1.5 rounded transition-all duration-200 ${
        activeType === 'candlestick' 
          ? 'bg-white text-blue-600' 
          : 'text-gray-600 hover:bg-gray-100'
      }`}
    >
      {activeType === 'candlestick' ? <ChartSelected /> : <Chart />}
    </button>
  </div>
);

