import React from "react";
import { Toggle } from "../../Toggle/Toggle";
import { setInstantBook,setAllowPets } from "../../../ReduxSlices/SearchSlice";


export const BookingOptionsFilter = ({ 
    instantBook, 
    allowPets, 
    dispatch 
  }) => {
    return (
      <div className="space-y-[12px]">
        <div className="font-semibold text-[18px]">
          Booking options
        </div>
        <div className="flex justify-between items-center">
          <div>
            <div className="font-semibold">Instant Book</div>
            <div className="text-[14px] text-[#959595]">
              Listings you can book without Host approval
            </div>
          </div>
          <Toggle
            status={instantBook}
            onChange={() => dispatch(setInstantBook(!instantBook))}
          />
        </div>
  
        <div className="flex justify-between items-center">
          <div>
            <div className="font-semibold">Allows pets</div>
            <div className="text-[14px] text-[#959595]">
              Bringing a service animal?
            </div>
          </div>
          <Toggle
            status={allowPets}
            onChange={() => dispatch(setAllowPets(!allowPets))}
          />
        </div>
      </div>
    );
  };
  
  export default BookingOptionsFilter;