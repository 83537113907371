import React from "react";
import {
  SelectionGroup,
  SelectionItem,
} from "../../../Components/Selection/Selection";

const TradeTabs = ({ tab, setTab }) => {
  return (
    <div className="flex items-center justify-between border-b border-gray-100 pb-3">
      <SelectionGroup className="flex gap-6 items-center" defaultItem={tab}>
        <SelectionItem
          SelectedItem={
            <div className="relative">
              <div className="text-black font-medium">Market</div>
              <div className="absolute bottom-[-12px] left-0 w-full h-[2px] bg-[#5b1dee]" />
            </div>
          }
          UnselectedItem={
            <div
              onClick={() => setTab(0)}
              className="text-gray-500 hover:text-gray-700 cursor-pointer"
            >
              Market
            </div>
          }
        />
        <SelectionItem
          SelectedItem={
            <div className="relative">
              <div className="text-black font-medium">Limit</div>
              <div className="absolute bottom-[-12px] left-0 w-full h-[2px] bg-[#5b1dee]" />
            </div>
          }
          UnselectedItem={
            <div
              onClick={() => setTab(1)}
              className="text-gray-500 hover:text-gray-700 cursor-pointer"
            >
              Limit
            </div>
          }
        />
      </SelectionGroup>
    </div>
  );
};

export default TradeTabs;
