import React, { useEffect, useState } from "react";
import { SelectionGroup, SelectionItem } from "../Selection/Selection";
import { LightIcon } from "../../AssetComponents/Images";
// import TradeTableRow from "../../Markets/Components/TradeTableRow";
// import TradeOrderRow from "../../Markets/Components/TradeOrderRow";
// import TradeHistoryRow from "../../Markets/Components/TradeHistoryRow";
import OrdersRow from "./TradeTableComponents/OrdersRow";
import TradesRow from "./TradeTableComponents/TradesRow";
import { useSelector } from "react-redux";
import { queryContract } from "../functions/Contract";
import { Testnet } from "@nibiruchain/nibijs";
import HistoryRow from "./TradeTableComponents/HistoryRow";

const TradingTable = ({
  // list,
  // setList,
  // trades,
  liquidationPrices,
  // handleCloseTrade,
  // setSelectedTradeIndex,
  setUpdateModalOpen,
  // handleCancelLimitOrder,
  // setModalFlag,
  // setShowModal,
  // tradeHistory,
  // isHistoryLoading,
  market,
}) => {
  const [list, setList] = useState(0);
  const [trades, setTrades] = useState([]);
  const account = useSelector((state) => state.auth.account);
  const perpsUpdate = useSelector((state) => state.trade.update);
  const getTrades = async () => {
    if (!account) {
      setTrades([]);
    }
    const msg = {
      get_trades: {
        trader: account,
      },
    };
    const res = await queryContract(
      process.env.REACT_APP_PERP_SMART_CONTRACT,
      msg,
      Testnet(2).endptTm
    );
    // console.log(res);
    let r = res;
    r?.sort((a, b) => b.open_timestamp - a.open_timestamp);
    setTrades(r?.filter((trade) => trade.market_index === market));
  };

  useEffect(() => {
    getTrades();
  }, [account, perpsUpdate, market]);

  return (
    <div className="mt-4 bg-white w-full p-[10px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
      <div className="w-full flex justify-between items-center">
        <SelectionGroup
          className="px-[6px] w-max flex py-[4px] gap-[8px] items-center rounded-[8px] bg-[#f6f6f6] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
          defaultItem={list}
        >
          <SelectionItem
            SelectedItem={
              <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                <div className="text-black font-semibold">Trades</div>
                <LightIcon />
              </div>
            }
            UnselectedItem={
              <div
                onClick={() => setList(0)}
                className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
              >
                <div className="text-[#959595]">Trades</div>
              </div>
            }
          />
          <SelectionItem
            SelectedItem={
              <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                <div className="text-black font-semibold">Orders</div>
                <LightIcon />
              </div>
            }
            UnselectedItem={
              <div
                onClick={() => setList(1)}
                className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
              >
                <div className="text-[#959595]">Orders</div>
              </div>
            }
          />
          <SelectionItem
            SelectedItem={
              <div className="cursor-pointer bg-white rounded-[8px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                <div className="text-black font-semibold">History</div>
                <LightIcon />
              </div>
            }
            UnselectedItem={
              <div
                onClick={() => setList(2)}
                className="cursor-pointer hover:bg-white rounded-[8px] w-[100px] flex justify-center"
              >
                <div className="text-[#959595]">History</div>
              </div>
            }
          />
        </SelectionGroup>
      </div>
      <hr className="mt-3" />

      {/* Modified Trades Table to match History format */}
      {list === 0 && (
        <div className="w-full">
          {!trades || trades.filter((trade) => trade.is_open).length === 0 ? (
            <div className="text-center py-4">No active trades</div>
          ) : (
            <>
              <div className="w-full grid grid-cols-9 h-[40px] text-[14px] text-[#8A8A8A] gap-4">
                <div className="flex items-center justify-center">Date</div>
                <div className="flex items-center justify-center">Pair</div>
                <div className="flex items-center justify-center">Type</div>
                <div className="flex items-center justify-center">
                  Entry Price
                </div>
                <div className="flex items-center justify-center">Leverage</div>
                <div className="flex items-center justify-center">Size</div>
                <div className="flex items-center justify-center">TP/SL</div>
                <div className="flex items-center justify-center">PnL</div>
                <div className="flex items-center justify-center">Action</div>
              </div>
              <div className="w-full flex flex-col">
                {trades
                  .filter(
                    (trade) => trade.is_open && trade.trade_type === "trade"
                  )
                  .map((trade) => (
                    <TradesRow trade={trade} />
                  ))}
              </div>
            </>
          )}
        </div>
      )}

      {/* Orders Table */}
      {list === 1 && (
        <div className="w-full">
          {!trades || trades.filter((trade) => trade.trade_type === "limit" && trade.is_open).length === 0 ? (
            <div className="text-center py-4">No active orders</div>
          ) : (
            <>
              <div className="w-full grid grid-cols-6 h-[40px] text-[14px] text-[#8A8A8A] gap-4">
                <div className="flex items-center justify-center">Date</div>
                <div className="flex items-center justify-center">Type</div>
                <div className="flex items-center justify-center">Pair</div>
                <div className="flex items-center justify-center">Size</div>
                <div className="flex items-center justify-center">Open Price</div>
                <div className="flex items-center justify-center">Action</div>
              </div>

              <div className="w-full flex flex-col">
                {trades?.filter((trade) => trade.trade_type === "limit" && trade.is_open).map((trade) => (
                  <OrdersRow
                    key={trade.user_trade_index}
                    trade={trade}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}

      {/* History Table */}
      {list === 2 && (
        <div className="w-full">
          <>
            <div className="w-full grid grid-cols-8 h-[40px] text-[14px] text-[#8A8A8A] gap-4">
              <div className="flex items-center justify-center">Date</div>
              <div className="flex items-center justify-center">Pair</div>
              <div className="flex items-center justify-center">Type</div>
              <div className="flex items-center justify-center">
                Entry Price
              </div>
              <div className="flex items-center justify-center">Leverage</div>
              <div className="flex items-center justify-center">Size</div>
              <div className="flex items-center justify-center">TP/SL</div>
              <div className="flex items-center justify-center">PnL</div>
            </div>

            <div className="w-full flex flex-col">
              {trades
                ?.filter((trade) => !trade.is_open)
                .map((trade) => (
                  <HistoryRow trade={trade} />
                ))}
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default TradingTable;
