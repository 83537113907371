import React from 'react';

const PropertySkeleton = () => {
  return (
    <div className="w-[380px] rounded-[20px] bg-white p-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
      <div className="relative w-full h-[250px]">
        <div className="w-full h-full rounded-[12px] bg-gray-200 animate-pulse" />
        <div className="absolute top-3 left-3">
          <div className="h-6 w-24 bg-gray-300 rounded-full animate-pulse" />
        </div>
        <div className="absolute top-3 right-3">
          <div className="h-8 w-8 bg-white rounded-full animate-pulse" />
        </div>
      </div>

      <div className="mt-3 space-y-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <div className="h-6 w-6 bg-gray-200 rounded-full animate-pulse" />
            <div className="h-6 w-32 bg-gray-200 rounded animate-pulse" />
          </div>
          <div className="flex items-center gap-2">
            <div className="h-8 w-8 bg-gray-200 rounded-full animate-pulse" />
            <div className="h-6 w-12 bg-gray-200 rounded animate-pulse" />
          </div>
        </div>

        <div className="flex items-center gap-2">
          <div className="h-5 w-5 bg-gray-200 rounded animate-pulse" />
          <div className="h-5 w-56 bg-gray-200 rounded animate-pulse" />
        </div>

        <div className="flex justify-between items-center">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="flex items-center gap-2">
              <div className="h-8 w-8 bg-gray-200 rounded-full animate-pulse" />
              <div className="h-5 w-8 bg-gray-200 rounded animate-pulse" />
              {i < 3 && <div className="h-5 w-[1px] bg-gray-200 mx-2" />}
            </div>
          ))}
        </div>

        <div className="h-[40px] w-full bg-gray-200 rounded-[10px] animate-pulse" />
      </div>
    </div>
  );
};

const PropertySkeletonGrid = ({ count = 6 }) => {
  return (
    <div className={`
      grid gap-4
      grid-cols-1 
      md:grid-cols-2 
      lg:grid-cols-2 
      xl:grid-cols-3 
      2xl:grid-cols-3
    `}>
      {[...Array(count)].map((_, index) => (
        <PropertySkeleton key={index} />
      ))}
    </div>
  );
};

export { PropertySkeleton, PropertySkeletonGrid };
