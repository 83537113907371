import React, { useRef, useEffect, useState } from "react";
import { Toggle } from "../../../Components/Toggle/Toggle";

// Helper function to format numbers with commas and fixed 2 decimal places
const formatWithCommas = (value) => {
  if (value === null || value === undefined || value === "") return "";

  // Handle non-string and non-number values safely
  if (typeof value !== "string" && typeof value !== "number") return "";

  // Parse value and ensure it has exactly 2 decimal places
  let numValue = value.toString();

  // If there's no decimal point, add one with two zeros
  if (!numValue.includes(".")) {
    numValue = numValue + ".00";
  } else {
    // If there's a decimal point, ensure exactly 2 digits after it
    const parts = numValue.split(".");
    // Pad with zeros if needed
    parts[1] = parts[1].padEnd(2, "0");
    // Truncate if more than 2 decimal places
    parts[1] = parts[1].substring(0, 2);
    numValue = parts.join(".");
  }

  // Add commas to the integer part
  const parts = numValue.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

const TPSLSection = ({
  isManualTPSL,
  setIsManualTPSL,
  longShort,
  tp,
  sl,
  tpPercentInput,
  slPercentInput,
  handleTpChange,
  handleSlChange,
  handleTpPercentInputChange,
  handleSlPercentInputChange,
  percentTP,
  percentSL,
  TP_EST,
  SL_EST,
  openPrice,
}) => {
  // Refs to keep track of cursor positions
  const tpPriceInputRef = useRef(null);
  const tpPercentInputRef = useRef(null);
  const slPriceInputRef = useRef(null);
  const slPercentInputRef = useRef(null);

  // Refs to store cursor positions
  const tpPriceSelectionStart = useRef(null);
  const tpPercentSelectionStart = useRef(null);
  const slPriceSelectionStart = useRef(null);
  const slPercentSelectionStart = useRef(null);

  // Format EST values with commas and fixed decimal
  const formattedTPEst = TP_EST !== undefined ? formatWithCommas(TP_EST) : "";
  const formattedSLEst = SL_EST !== undefined ? formatWithCommas(SL_EST) : "";

  // NEW: local focus states for each input
  const [isTpFocused, setIsTpFocused] = useState(false);
  const [isSlFocused, setIsSlFocused] = useState(false);
  const [isTpPercentFocused, setIsTpPercentFocused] = useState(false);
  const [isSlPercentFocused, setIsSlPercentFocused] = useState(false);

  // Effect hooks for Take Profit price input cursor position
  useEffect(() => {
    if (tpPriceInputRef.current && tpPriceSelectionStart.current !== null) {
      tpPriceInputRef.current.setSelectionRange(
        tpPriceSelectionStart.current,
        tpPriceSelectionStart.current
      );
    }
  }, [tp]);

  // Effect hooks for Take Profit percent input cursor position
  useEffect(() => {
    if (tpPercentInputRef.current && tpPercentSelectionStart.current !== null) {
      tpPercentInputRef.current.setSelectionRange(
        tpPercentSelectionStart.current,
        tpPercentSelectionStart.current
      );
    }
  }, [tpPercentInput]);

  // Effect hooks for Stop Loss price input cursor position
  useEffect(() => {
    if (slPriceInputRef.current && slPriceSelectionStart.current !== null) {
      slPriceInputRef.current.setSelectionRange(
        slPriceSelectionStart.current,
        slPriceSelectionStart.current
      );
    }
  }, [sl]);

  // Effect hooks for Stop Loss percent input cursor position
  useEffect(() => {
    if (slPercentInputRef.current && slPercentSelectionStart.current !== null) {
      slPercentInputRef.current.setSelectionRange(
        slPercentSelectionStart.current,
        slPercentSelectionStart.current
      );
    }
  }, [slPercentInput]);

  // Handle Take Profit price change
  const handleTpPriceChange = (e) => {
    tpPriceSelectionStart.current = e.target.selectionStart;
    const val = e.target.value.replace(/,/g, "");

    if (val === "") {
      handleTpChange(null);
      return;
    }

    if (/^\d*\.?\d*$/.test(val)) {
      handleTpChange(val);
    }
  };

  // Handle Take Profit percent change
  const handleTpPercentChange = (e) => {
    tpPercentSelectionStart.current = e.target.selectionStart;
    const val = e.target.value.replace(/,/g, "");
    handleTpPercentInputChange(val);
  };

  // Handle Stop Loss price change
  const handleSlPriceChange = (e) => {
    slPriceSelectionStart.current = e.target.selectionStart;
    const val = e.target.value.replace(/,/g, "");

    if (val === "") {
      handleSlChange(null);
      return;
    }

    if (/^\d*\.?\d*$/.test(val)) {
      handleSlChange(val);
    }
  };

  // Handle Stop Loss percent change
  const handleSlPercentChange = (e) => {
    slPercentSelectionStart.current = e.target.selectionStart;
    const val = e.target.value.replace(/,/g, "");
    handleSlPercentInputChange(val);
  };

  // Render Take Profit section
  const renderTakeProfit = () => {
    return (
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <span className="text-sm font-medium">Take Profit</span>
          {/* <span className="ml-2 text-xs text-green-500">
            Est: ${formattedTPEst}
          </span> */}
        </div>
        <div className="grid grid-cols-2 gap-2">
          <input
            ref={tpPriceInputRef}
            type="text"
            value={isTpFocused ? tp ?? "" : formatWithCommas(tp ?? "")}
            onFocus={() => setIsTpFocused(true)}
            onBlur={() => {
              setIsTpFocused(false);
              const parsed = parseFloat(tp);
              if (!isNaN(parsed)) {
                handleTpChange(parsed.toFixed(2));
              }
            }}
            onChange={handleTpPriceChange}
            placeholder="Price"
            className="p-3 bg-gray-50 rounded-lg outline-none"
          />
          <div className="relative">
            <input
              ref={tpPercentInputRef}
              type="text"
              value={
                isTpPercentFocused
                  ? tpPercentInput
                  : formatWithCommas(tpPercentInput)
              }
              onFocus={() => setIsTpPercentFocused(true)}
              onBlur={() => {
                setIsTpPercentFocused(false);
                const val = parseFloat(tpPercentInput);
                if (isNaN(val)) {
                  handleTpPercentInputChange("0.00");
                } else {
                  handleTpPercentInputChange(val.toFixed(2));
                }
              }}
              onChange={handleTpPercentChange}
              placeholder="Percentage"
              className="p-3 pr-8 bg-gray-50 rounded-lg outline-none w-full text-green-500"
            />
            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-green-500">
              %
            </span>
          </div>
        </div>
      </div>
    );
  };

  // Render Stop Loss section
  const renderStopLoss = () => {
    return (
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <span className="text-sm font-medium">Stop Loss</span>
          {/* <span className="ml-2 text-xs text-red-500">
            Est: ${formattedSLEst}
          </span> */}
        </div>
        <div className="grid grid-cols-2 gap-2">
          <input
            ref={slPriceInputRef}
            type="text"
            value={isSlFocused ? sl ?? "" : formatWithCommas(sl ?? "")}
            onFocus={() => setIsSlFocused(true)}
            onBlur={() => {
              setIsSlFocused(false);
              const parsed = parseFloat(sl);
              if (!isNaN(parsed)) {
                handleSlChange(parsed.toFixed(2));
              }
            }}
            onChange={handleSlPriceChange}
            placeholder="Price"
            className="p-3 bg-gray-50 rounded-lg outline-none"
          />
          <div className="relative">
            <input
              ref={slPercentInputRef}
              type="text"
              value={
                isSlPercentFocused
                  ? slPercentInput
                  : formatWithCommas(slPercentInput)
              }
              onFocus={() => setIsSlPercentFocused(true)}
              onBlur={() => {
                setIsSlPercentFocused(false);
                const val = parseFloat(slPercentInput);
                if (isNaN(val)) {
                  handleSlPercentInputChange("0.00");
                } else {
                  handleSlPercentInputChange(val.toFixed(2));
                }
              }}
              onChange={handleSlPercentChange}
              placeholder="Percentage"
              className="p-3 pr-8 bg-gray-50 rounded-lg outline-none w-full text-red-500"
            />
            <span className="absolute right-3 top-1/2 -translate-y-1/2 text-red-500">
              %
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="text-sm text-gray-500">Manual TP/SL</div>
        <Toggle
          status={isManualTPSL}
          onChange={() => setIsManualTPSL(!isManualTPSL)}
        />
      </div>

      {isManualTPSL && (
        <div className="space-y-4">
          {longShort === 0 ? (
            <>
              {renderTakeProfit()}
              {renderStopLoss()}
            </>
          ) : (
            <>
              {renderStopLoss()}
              {renderTakeProfit()}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TPSLSection;
