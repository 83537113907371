import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  liquidationPrice: 0,
  currentLeverage: 1,
  currentCollateral: 0,
  currentPrice: 0,
  dubaiPrice: 0
};

export const liquidationSlice = createSlice({
  name: 'liquidation',
  initialState,
  reducers: {
    updateLiquidationPrice: (state, action) => {
      const { price: openPrice, leverage, collateral, isLong } = action.payload;
      
      const LIQ_THRESHOLD_P = 0.9; // 90%
      const OPEN_FEE = 0.001; // 0.1%
      const CLOSE_FEE = 0.001; // 0.1%

      // Convert all inputs to numbers
      const numericOpenPrice = Number(openPrice);
      const numericLeverage = Number(leverage);
      const numericCollateral = Number(collateral);

      // Calculate collateral after fees
      const collateralNetOfFees = numericCollateral * (1 - numericLeverage * (OPEN_FEE + CLOSE_FEE));
      
      // Calculate liquidation threshold
      const collateralLiqNegativePnl = LIQ_THRESHOLD_P * collateralNetOfFees;
      
      // Calculate distance to liquidation price
      const liqPriceDistance = (collateralLiqNegativePnl * numericOpenPrice) / 
                              (collateralNetOfFees * numericLeverage);

      // Calculate final liquidation price based on position type
      const liquidationPrice = isLong 
        ? numericOpenPrice - liqPriceDistance  // Long: price goes down
        : numericOpenPrice + liqPriceDistance; // Short: price goes up

      console.log('Liquidation calculation:', {
        position: isLong ? 'LONG' : 'SHORT',
        openPrice: numericOpenPrice,
        leverage: numericLeverage,
        collateral: numericCollateral,
        collateralNetOfFees,
        liqPriceDistance,
        liquidationPrice,
        percentageFromEntry: ((liquidationPrice - numericOpenPrice) / numericOpenPrice * 100).toFixed(2) + '%'
      });

      state.liquidationPrice = liquidationPrice;
      state.currentPrice = numericOpenPrice;
      state.currentLeverage = numericLeverage;
      state.currentCollateral = numericCollateral;
    },
    setDubaiPrice: (state, action) => {
      state.dubaiPrice = action.payload;
      state.currentPrice = action.payload;
    }
  }
});

export const { updateLiquidationPrice, setDubaiPrice } = liquidationSlice.actions;
export default liquidationSlice.reducer;