import React from "react";
import { ToyCubes,BookFairyTale,BabyBedroom } from "../../Amenities/Family";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";

const FAMILY_AMENITIES = [
    { id: 'toyscubes', Icon: ToyCubes },
    { id: 'fairytale', Icon: BookFairyTale },
    { id: 'babybedroom', Icon: BabyBedroom }
  ];

  
const FamilyAmenities = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
      const currentAmenities = metaDetails.amenities["Family"] || [];
      const isSelected = currentAmenities.includes(amenityId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          "Family": isSelected
            ? currentAmenities.filter(item => item !== amenityId)
            : [...currentAmenities, amenityId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {FAMILY_AMENITIES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities["Family"]?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleAmenity(id)} />
            ) : (
              <PlusIcon onClick={() => toggleAmenity(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default FamilyAmenities;