import React from 'react'

function Graph() {
  return (
    <div><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.25 6.25H15V3.75H13.75V6.25H12.5V13.75H13.75V16.25H15V13.75H16.25V6.25ZM15 12.5H13.75V7.5H15V12.5ZM8.75 5H7.5V2.5H6.25V5H5V11.25H6.25V13.75H7.5V11.25H8.75V5ZM7.5 10H6.25V6.25H7.5V10Z" fill="#959595"/>
    <path d="M18.75 18.75H2.5C2.16848 18.75 1.85054 18.6183 1.61612 18.3839C1.3817 18.1495 1.25 17.8315 1.25 17.5V1.25H2.5V17.5H18.75V18.75Z" fill="#959595"/>
    </svg>
    </div>
  )
}

export default Graph