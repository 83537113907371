import TradingChart from "../../../../../Components/PerpsComponents/TradingChart";
import TradingForm from "../../../../../Components/PerpsComponents/TradingForm";
import TradingHeader from "../../../../../Components/PerpsComponents/TradingHeader";
import TradingTable from "../../../../../Components/PerpsComponents/TradingTable";
import { TradingTopBar } from "../../../../../Components/PerpsComponents/TradingTopBar";

export const DubaiTrading = () => {
  return (
    <div className="w-full h-[calc(100vh-100px)] overflow-auto">
      <div className="flex gap-[20px] w-[80vw] mx-auto my-[20px]">
        <div className="w-[calc(80vw-300px)] h-max">
          <TradingHeader />
          <TradingTopBar tokenIndex={5} />
          <TradingChart chartType={"dubai"} />
          <TradingTable market={process.env.REACT_APP_MARKET_INDEXES_DUBAI} />
        </div>
        <TradingForm
          tokenIndex={5}
          marketIndex={process.env.REACT_APP_MARKET_INDEXES_DUBAI}
        />
      </div>
    </div>
  );
};
