import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { ChevronDown } from 'lucide-react';
import { api } from '../../../Components/functions/Apiwithouttoken';
import { formatNumber } from '../../../Components/utils/formatter';

const timeIntervals = [
  { value: '1W', label: '1W' },
  { value: '1M', label: '1M' },
  { value: '3M', label: '3M' },
  { value: '6M', label: '6M' },
  { value: '1Y', label: '1Y' },
  { value: 'ALL', label: 'ALL' }
];

const DubaiPriceChart = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [interval, setInterval] = useState('1M');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState(null);

  // Function to filter data based on interval
  const filterDataByInterval = (fullData, selectedInterval) => {
    if (!fullData.length) return;

    const now = new Date();
    let filterDate = new Date();

    switch (selectedInterval) {
      case '1W':
        filterDate.setDate(now.getDate() - 7);
        break;
      case '1M':
        filterDate.setMonth(now.getMonth() - 1);
        break;
      case '3M':
        filterDate.setMonth(now.getMonth() - 3);
        break;
      case '6M':
        filterDate.setMonth(now.getMonth() - 6);
        break;
      case '1Y':
        filterDate.setFullYear(now.getFullYear() - 1);
        break;
      case 'ALL':
        setFilteredData(fullData);
        return;
      default:
        filterDate.setMonth(now.getMonth() - 1);
    }

    const filtered = fullData.filter(
      item => new Date(item.date) >= filterDate
    );
    setFilteredData(filtered);
  };

  // Single API call on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api('parcl/data');
        if (response.success && response.data.PriceData) {
          const sortedData = response.data.PriceData.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          setData(sortedData);
          setMetrics(response.data.metrics);
          filterDataByInterval(sortedData, interval);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures single API call

  // Handle interval changes using existing data
  useEffect(() => {
    filterDataByInterval(data, interval);
  }, [interval]);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { year:"numeric", month: 'short', day: 'numeric' });
  };

  const formatPrice = (price) => {
    return formatNumber(price, {
      currency: true,
      minDecimals: 2,
      maxDecimals: price < 1 ? 6 : 2
    });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 shadow-lg rounded-lg border">
          <p className="text-gray-600">{formatDate(payload[0].payload.date)}</p>
          <p className="text-lg font-semibold">{formatPrice(payload[0].value)}</p>
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div className="w-full bg-white p-6 rounded-xl shadow-sm h-96 flex items-center justify-center">
        <div className="text-gray-500">Loading chart data...</div>
      </div>
    );
  }

  return (
    <div className="w-full bg-white p-6 rounded-xl shadow-sm">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Dubai Price Chart</h2>
          {metrics && (
            <p className="text-sm text-gray-500 mt-1">
              Current Index Price: {metrics.indexPrice}
            </p>
          )}
        </div>
        
        <div className="relative">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="px-4 py-2 bg-white border rounded-lg flex items-center gap-2 hover:bg-gray-50 transition-colors"
          >
            <span className="text-sm font-medium">{interval}</span>
            <ChevronDown className="w-4 h-4" />
          </button>

          {isOpen && (
            <div className="absolute right-0 mt-2 w-24 bg-white rounded-lg shadow-lg border z-10">
              {timeIntervals.map((item) => (
                <button
                  key={item.value}
                  onClick={() => {
                    setInterval(item.value);
                    setIsOpen(false);
                  }}
                  className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 transition-colors first:rounded-t-lg last:rounded-b-lg"
                >
                  {item.label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="h-96">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart 
            data={filteredData} 
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis 
              dataKey="date" 
              tickFormatter={formatDate}
              tick={{ fill: '#6B7280' }}
              axisLine={{ stroke: '#E5E7EB' }}
              tickLine={{ stroke: '#E5E7EB' }}
            />
            <YAxis 
              tickFormatter={(value) => formatNumber(value, { currency: true })}
              tick={{ fill: '#6B7280' }}
              axisLine={{ stroke: '#E5E7EB' }}
              tickLine={{ stroke: '#E5E7EB' }}
              domain={['auto', 'auto']}
            />
            <Tooltip 
              content={<CustomTooltip />}
              formatter={(value) => formatNumber(value, { currency: true })}
              labelFormatter={formatDate}
            />
            <Line 
              type="monotone" 
              dataKey="price" 
              stroke="#2563EB" 
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 6, fill: "#2563EB" }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DubaiPriceChart;