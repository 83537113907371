import React from "react";
import { useSelector } from "react-redux";
import { executePerps } from "../../functions/Contract";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Testnet } from "@nibiruchain/nibijs";
// Add formatDate function since it's not imported
// Add formatDate function since it's not imported
const formatDate = (timestamp) => {
  try {
    // Input timestamp in nanoseconds
    // let timestamp = 1741355811757504645;
    // Convert nanoseconds to milliseconds
    let milliseconds = timestamp / 1e6;

    // Create a Date object
    let date = new Date(milliseconds);

    // Extract the time components and format them
    let hours = String(date.getHours()).padStart(2, "0");
    let minutes = String(date.getMinutes()).padStart(2, "0");

    // Extract the date components and format them
    let month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is 0-indexed
    let day = String(date.getDate()).padStart(2, "0");
    let year = date.getFullYear();

    // Output in the desired format: hh:mm MM/DD/YYYY
    let formattedDate = `${hours}:${minutes} ${month}/${day}/${year}`;

    return formattedDate;
  } catch (error) {
    console.error("Error formatting date:", error, timestamp);
    return "Format Error";
  }
};

const HistoryRow = ({ trade }) => {
  const currentPrice = useSelector((state) => state.trade.currentPrice);
  const account = useSelector((state) => state.auth.account);
  const dispatch = useDispatch();
  // Format price with currency sign
  const formatPrice = (price) => {
    if (!price) return "$0.00";
    const num = parseFloat(price);
    return isNaN(num)
      ? "$0.00"
      : `$${num.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
  };

  // Format with currency sign and amount
  const formatAmount = (amount, leverage, denom = "USDC") => {
    if (!amount) return `$0.00 ${denom}`;
    const num =
      (parseFloat(amount) * parseFloat(leverage)) /
      10 ** process.env.REACT_APP_USDC_DECIMALS;
    return isNaN(num)
      ? `$0.00 ${denom}`
      : `$${num.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} ${denom}`;
  };

  // Format PnL with sign and percentage
  const formatPnL = (size, isOpen, openPrice, isLong, leverage, closePrice) => {
    // if (!isOpen) return { pnl: "$0.00", percentage: "0.00", isProfit: false };

    const openPriceNum = parseFloat(openPrice);
    const closePriceNum = parseFloat(closePrice);
    console.log(openPriceNum, closePriceNum);
    // Calculate PnL based on position direction
    let diff;
    let isProfit;

    if (isLong) {
      // Long position: profit when current > open
      diff = closePriceNum - openPriceNum;
      isProfit = closePriceNum > openPriceNum;
    } else {
      // Short position: profit when current < open
      diff = openPriceNum - closePriceNum;
      isProfit = closePriceNum < openPriceNum;
    }

    const percentage = (
      (Math.abs(diff) / openPriceNum) *
      leverage *
      100
    ).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return {
      pnl: (
        (parseFloat(size) * parseFloat(percentage)) /
        100 /
        10 ** 6
      ).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      percentage: percentage,
      isProfit: isProfit,
    };
  };

  // Get market name from index
  const getMarketName = (marketIndex) => {
    const markets = {
      "MarketIndex(0)": "BTC/USDC",
      "MarketIndex(1)": "ETH/USDC",
      "MarketIndex(2)": "ATOM/USDC",
      "MarketIndex(3)": "DUBAI/USDC",
    };
    return markets[marketIndex] || "UNKNOWN/USDC";
  };

  // Format trade type (LONG/SHORT)
  const getTradeType = (direction) => {
    return direction ? "LONG" : "SHORT";
  };

  // Format leverage with x
  const formatLeverage = (leverage) => {
    if (leverage === undefined || leverage === null) {
      return "1.00x";
    }
    const leverageNum = parseFloat(leverage);
    return isNaN(leverageNum) ? "1.00x" : `${leverageNum.toFixed(2)}x`;
  };

  // Format date with time
  const dateFormatted = trade.open_timestamp
    ? formatDate(trade.open_timestamp)
    : "N/A";

  // Format trade type (LONG/SHORT)
  const getType = (direction) => {
    return direction === "trade" ? "MARKET" : "LIMIT";
  };

  // Get market pair
  const market = getMarketName(trade.market_index);

  // Get trade type (LONG/SHORT)
  const type = getTradeType(trade.long);
  const tradeType = getType(trade.trade_type);

  // Format entry price
  const price = formatPrice(trade.open_price);

  // Format leverage
  const leverage = formatLeverage(trade.leverage);

  // Format position size
  const size = formatAmount(trade.collateral_amount, trade.leverage);

  // Format PnL and percentage
  const pnlData = formatPnL(
    trade.collateral_amount,
    trade.is_open,
    trade.open_price,
    trade.long,
    trade.leverage,
    trade.close_price
  );

  const formatTpSl = (tp, sl) => {
    const formatNumber = (val) => {
      const num = parseFloat(val);
      return isNaN(num)
        ? "0.00"
        : num.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
    };

    return {
      tp: formatNumber(tp),
      sl: formatNumber(sl),
    };
  };

  const tpSl = formatTpSl(trade.tp, trade.sl);

  // Handle row click
  const handleRowClick = () => {
    // if (isActiveTrade) {
    // setSelectedTradeIndex(trade.user_trade_index);
    // setModalFlag("action");
    // setShowModal(true);
    // }
  };

  // const pnlClass = () => {
  //   const pnlValue = isActiveTrade
  //     ? parseFloat(trade.unrealized_pnl || 0)
  //     : parseFloat(trade.realized_pnl || 0);
  //   return pnlValue >= 0 ? "text-[#5DBE89]" : "text-[#EB4245]";
  // };

  return (
    <div
      onClick={handleRowClick}
      className={`grid grid-cols-8 py-2 border-b border-gray-200 hover:bg-gray-50 cursor-pointer text-xs`}
    >
      <div className="flex items-center justify-center px-1">{dateFormatted}</div>
      <div className="flex items-center justify-center px-1">{market}</div>
      <div
        className={`flex items-center justify-center px-1 ${
          trade.long ? "text-green-500" : "text-red-500"
        }`}
      >
        <span className="text-gray-700 mr-0.5">{tradeType}/</span>
        {type}
      </div>
      
      {/* Entry Price - Increased font size */}
      <div className="flex items-center justify-center px-1">
        <span className="text-base font-medium">{price}</span>
      </div>
      
      {/* Leverage - Increased font size */}
      <div className="flex items-center justify-center px-1">
        <span className="text-base font-medium">{leverage}</span>
      </div>
      
      <div className="flex items-center justify-center px-1">{size}</div>
      
      <div className="flex items-center justify-center px-1">
        <div className="flex flex-col items-center space-y-1 w-full">
          <div className="text-gray-500 w-full text-center text-xs">${tpSl.tp || "-"}</div>
          <div className="text-gray-700 w-full text-center text-xs">${tpSl.sl || "-"}</div>
        </div>
      </div>
      
      {/* PnL - Increased font size */}
      <div className={`flex items-center justify-center px-1`}>
        <div
          className={
            pnlData.isProfit
              ? "flex flex-col items-center text-green-500 space-y-0.5"
              : "flex flex-col items-center text-red-500 space-y-0.5"
          }
        >
          <div className="font-medium text-base">${pnlData.pnl}</div>
          <div className="text-base">({pnlData.percentage}%)</div>
        </div>
      </div>
    </div>
  );
};

export default HistoryRow;