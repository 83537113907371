import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { LightIcon } from "../../../AssetComponents/Images";
import { PurpleButton } from "../../../Components/Buttons/PurpleButton";
import { GreenButton } from "../../../Components/Buttons/GreenButton";
import { RedButton } from "../../../Components/Buttons/RedButton";
import { toast } from "react-toastify";
import { executePerps } from "../../functions/Contract";
import { Testnet } from "@nibiruchain/nibijs";
import { useDispatch, useSelector } from "react-redux";

const formatNumber = (val) => {
  const num = parseFloat(val);
  return isNaN(num) ? "" : num.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const unformatNumber = (val) => val.replace(/,/g, "");

const UpdateActionsModal = ({
  isOpen,
  setIsOpen,
  trade,
  tp,
  setTp,
  sl,
  setSl,
  leverage_value,
  setLeverageValue,
  selectedTradeIndex,
}) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.auth.account);
  const tabs = [
    { id: "position", label: "Position" },
    { id: "size", label: "Size" },
    { id: "limit", label: "Limit Order" },
    { id: "actions", label: "Actions" },
  ];
  const PendingOrderType = {
    LIMIT_OPEN: "limit_open",
    STOP_OPEN: "stop_open",
    TP_CLOSE: "tp_close",
    SL_CLOSE: "sl_close",
    LIQ_CLOSE: "liq_close",
  };
  const [activeTab, setActiveTab] = useState("position");
  const [modalInputs, setModalInputs] = useState({
    price: trade.open_price,
    newTp: trade.tp,
    newSl: trade.sl,
    leverage: trade.leverage,
    slippage_p: "0.5",
    leverageDelta: "",
    collateralDelta: "",
    orderType: "limit_open",
  });
  
  // NEW: local focused field tracker
  const [focusedField, setFocusedField] = useState("");

  const handleUpdateLimitOrder = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        update_open_limit_order: {
          trade_index: trade.user_trade_index,
          price: modalInputs.price,
          tp: modalInputs.newTp,
          sl: modalInputs.newSl,
          slippage_p: modalInputs.slippage_p,
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }
      return res;
    } catch (err) {
      return false;
    }
  };

  const handleCancelLimitOrder = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        cancel_open_limit_order: {
          trade_index: trade.user_trade_index,
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }

      return res;
    } catch (err) {
      return false;
    }
  };

  const handleUpdateTp = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        update_tp: {
          trade_index: trade.user_trade_index,
          new_tp: modalInputs.newTp,
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }
      return res;
    } catch (err) {
      return false;
    }
  };

  const handleUpdateSl = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        update_sl: {
          trade_index: trade.user_trade_index,
          new_sl: modalInputs.newSl,
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }
      return res;
    } catch (err) {
      return false;
    }
  };

  const handleUpdateLeverage = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        update_leverage: {
          trade_index: trade.user_trade_index,
          new_leverage: modalInputs.leverage,
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }
      return res;
    } catch (err) {
      return false;
    }
  };

  const handleIncreasePositionSize = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        increase_position_size: {
          trade_index: trade.user_trade_index,
          leverage_delta: modalInputs.leverageDelta,
          open_price: modalInputs.price,
          slippage_p: modalInputs.slippage_p,
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }
      return res;
    } catch (err) {
      return false;
    }
  };

  const handleDecreasePositionSize = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        decrease_position_size: {
          trade_index: trade.user_trade_index,
          leverage_delta: modalInputs.leverageDelta || "0",
          collateral_delta: modalInputs.collateralDelta || "0",
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }
      return res;
    } catch (err) {
      return false;
    }
  };

  const handleTriggerTrade = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        trigger_trade: {
          trader: account,
          trade_index: trade.user_trade_index,
          order_type: modalInputs.orderType,
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }
      return res;
    } catch (err) {
      return false;
    }
  };

  const handleCloseTrade = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        close_trade_market: {
          trade_index: trade.user_trade_index,
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }

      toast.success("Trade closed successfully!");

      return res;
    } catch (err) {
      console.error("Close trade error:", err);
      toast.error(`Failed to close trade: ${err.message}`);
      return false;
    }
  };

  return (
    <Modal
      open={isOpen}
      center
      onClose={() => setIsOpen(false)}
      classNames={{
        modal:
          "min-w-[500px] min-h-[550px] max-h-[550px] rounded-[8px] border-[1px] border-[#E3E3E3]",
      }}
    >
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-4 text-[#202020] font-bold">
          Manage Position
        </h2>

        <div className="flex gap-2 mb-4">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`py-[4px] cursor-pointer rounded-[10px] w-[120px] flex justify-center ${
                activeTab === tab.id
                  ? "bg-white hover:bg-[#f6f6f6] rounded-[10px] gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
                  : "hover:bg-[#f6f6f6]"
              }`}
            >
              {activeTab === tab.id ? (
                <div className="flex items-center gap-[10px]">
                  <div className="text-black font-semibold">{tab.label}</div>
                  <LightIcon />
                </div>
              ) : (
                <div className="text-[#959595]">{tab.label}</div>
              )}
            </div>
          ))}
        </div>

        {activeTab === "position" && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-semibold mt-2 mb-2 text-[#202020]">
                Take Profit
              </label>
              <div className="w-full flex gap-x-2">
                <input
                  type="text"
                  value={
                    focusedField === "newTp"
                      ? modalInputs.newTp || ""
                      : formatNumber(modalInputs.newTp)
                  }
                  onFocus={() => setFocusedField("newTp")}
                  onBlur={() => {
                    setFocusedField("");
                    const parsed = parseFloat(modalInputs.newTp);
                    if (!isNaN(parsed)) {
                      setModalInputs({ ...modalInputs, newTp: parsed.toFixed(2) });
                    }
                  }}
                  onChange={(e) =>
                    setModalInputs({
                      ...modalInputs,
                      newTp: unformatNumber(e.target.value),
                    })
                  }
                  className="w-[75%] shadow-md px-3 py-2 border rounded-md outline-none"
                  placeholder="Enter new take profit"
                />
                <div className="w-[25%]">
                  <GreenButton onClick={() => handleUpdateTp()} text={"Update TP"} />
                </div>
              </div>
            </div>

            {/* Stop Loss Section */}
            <div>
              <label className="block text-sm font-medium mb-2 text-[#202020] font-semibold">
                Stop Loss
              </label>
              <div className="flex gap-x-2">
                <input
                  type="text"
                  value={
                    focusedField === "newSl"
                      ? modalInputs.newSl || ""
                      : formatNumber(modalInputs.newSl)
                  }
                  onFocus={() => setFocusedField("newSl")}
                  onBlur={() => {
                    setFocusedField("");
                    const parsed = parseFloat(modalInputs.newSl);
                    if (!isNaN(parsed)) {
                      setModalInputs({ ...modalInputs, newSl: parsed.toFixed(2) });
                    }
                  }}
                  onChange={(e) =>
                    setModalInputs({
                      ...modalInputs,
                      newSl: unformatNumber(e.target.value),
                    })
                  }
                  className="w-[75%] shadow-md px-3 py-2 border rounded-md outline-none"
                  placeholder="Enter new stop loss"
                />
                <div className="w-[25%]">
                  <RedButton
                    text={"Update SL"}
                    onClick={() => handleUpdateSl()}
                  />
                </div>
              </div>
            </div>

            {/* Leverage Section */}
            <div>
              <label className="block text-sm font-medium mb-2">Leverage</label>
              <div className="flex gap-x-2">
                <input
                  type="text"
                  value={
                    focusedField === "leverage"
                      ? modalInputs.leverage || ""
                      : formatNumber(modalInputs.leverage)
                  }
                  onFocus={() => setFocusedField("leverage")}
                  onBlur={() => {
                    setFocusedField("");
                    const parsed = parseFloat(modalInputs.leverage);
                    if (!isNaN(parsed)) {
                      setModalInputs({ ...modalInputs, leverage: parsed.toFixed(2) });
                    }
                  }}
                  onChange={(e) =>
                    setModalInputs({
                      ...modalInputs,
                      leverage: unformatNumber(e.target.value),
                    })
                  }
                  className="w-[75%] shadow-md px-3 py-2 border rounded-md outline-none"
                  placeholder="Enter new leverage"
                />
                <div className="w-[25%]">
                  <PurpleButton
                    text={"Update"}
                    onClick={() => handleUpdateLeverage()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Size Tab Content */}
        {activeTab === "size" && (
          <div className="space-y-4">
            {/* Increase Position Size Section */}
            <div>
              <h3 className="font-semibold mb-2 text-[#202020]">
                Increase Position Size
              </h3>
              <input
                type="text"
                value={modalInputs?.leverageDelta || ""}
                onChange={(e) =>
                  setModalInputs({
                    ...modalInputs,
                    leverageDelta: unformatNumber(e.target.value),
                  })
                }
                onBlur={() =>
                  setModalInputs({
                    ...modalInputs,
                    leverageDelta: formatNumber(modalInputs.leverageDelta),
                  })
                }
                placeholder="Leverage Delta"
                className="w-full shadow-md px-3 py-2 border rounded-md"
              />
              <input
                type="text"
                value={modalInputs?.price || ""}
                onChange={(e) =>
                  setModalInputs({
                    ...modalInputs,
                    price: unformatNumber(e.target.value),
                  })
                }
                onBlur={() =>
                  setModalInputs({
                    ...modalInputs,
                    price: formatNumber(modalInputs.price),
                  })
                }
                placeholder="Open Price"
                className="w-full shadow-md mt-2 px-3 py-2 border rounded-md"
              />
              <div className="mt-2">
                <GreenButton
                  text={"Increase Position"}
                  onClick={() => handleIncreasePositionSize()}
                />
              </div>
            </div>

            {/* Decrease Position Size Section */}
            <div>
              <h3 className="font-semibold mb-2 text-[#202020]">
                Decrease Position Size
              </h3>
              <input
                type="text"
                value={modalInputs?.collateralDelta || ""}
                onChange={(e) =>
                  setModalInputs({
                    ...modalInputs,
                    collateralDelta: unformatNumber(e.target.value),
                  })
                }
                onBlur={() =>
                  setModalInputs({
                    ...modalInputs,
                    collateralDelta: formatNumber(modalInputs.collateralDelta),
                  })
                }
                placeholder="Collateral Delta"
                className="w-full mt-2 px-3 py-2 border rounded-md"
              />
              <input
                type="text"
                step="0.1"
                value={modalInputs.leverageDelta || ""}
                onChange={(e) =>
                  setModalInputs({
                    ...modalInputs,
                    leverageDelta: unformatNumber(e.target.value),
                  })
                }
                onBlur={() =>
                  setModalInputs({
                    ...modalInputs,
                    leverageDelta: formatNumber(modalInputs.leverageDelta),
                  })
                }
                placeholder="Leverage Delta"
                className="w-full mt-2 px-3 py-2 border rounded-md"
              />
              <div className="mt-2">
                <RedButton
                  text={"Decrease Position"}
                  onClick={() => handleDecreasePositionSize()}
                />
              </div>
            </div>
          </div>
        )}

        {/* Limit Order Tab Content */}
        {activeTab === "limit" && trade.trade_type === "limit" && (
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold mb-2 text-[#202020]">
                Update Limit Order
              </h3>
              <div className="grid grid-cols-2 gap-2 mt-2">
                <div className="w-full">
                  <div className="">Price</div>
                  <input
                    type="text"
                    value={modalInputs.price || ""}
                    onChange={(e) =>
                      setModalInputs({ ...modalInputs, price: unformatNumber(e.target.value) })
                    }
                    onBlur={() =>
                      setModalInputs({ ...modalInputs, price: formatNumber(modalInputs.price) })
                    }
                    placeholder="New Price"
                    className="w-full shadow-md px-3 py-2 border rounded-md"
                  />
                </div>
                <div className="w-full">
                  <div className="">Slippage</div>
                  <input
                    type="text"
                    step={0.1}
                    max={1}
                    min={0.1}
                    value={modalInputs.slippage_p || ""}
                    onChange={(e) =>
                      setModalInputs({
                        ...modalInputs,
                        slippage_p: unformatNumber(e.target.value),
                      })
                    }
                    onBlur={() =>
                      setModalInputs({
                        ...modalInputs,
                        slippage_p: formatNumber(modalInputs.slippage_p),
                      })
                    }
                    placeholder="Slippage percent"
                    className="w-full shadow-md px-3 py-2 border rounded-md"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 mt-2">
                <div className="w-full">
                  <div className="">TP</div>
                  <input
                    type="text"
                    value={modalInputs.newTp || ""}
                    onChange={(e) =>
                      setModalInputs({ ...modalInputs, newTp: unformatNumber(e.target.value) })
                    }
                    onBlur={() =>
                      setModalInputs({ ...modalInputs, newTp: formatNumber(modalInputs.newTp) })
                    }
                    placeholder="Take Profit"
                    className="w-full px-3 shadow-md py-2 border rounded-md"
                  />
                </div>
                <div>
                  <div className="">SL</div>
                  <input
                    type="text"
                    value={modalInputs.newSl || ""}
                    onChange={(e) =>
                      setModalInputs({ ...modalInputs, newSl: unformatNumber(e.target.value) })
                    }
                    onBlur={() =>
                      setModalInputs({ ...modalInputs, newSl: formatNumber(modalInputs.newSl) })
                    }
                    placeholder="Stop Loss"
                    className="w-full px-3 shadow-md py-2 border rounded-md"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 mt-6">
                <PurpleButton
                  text={"Update Limit"}
                  onClick={() => handleUpdateLimitOrder()}
                />
                <RedButton
                  text={"Cancel Limit"}
                  onClick={() => handleCancelLimitOrder()}
                />
              </div>
            </div>
          </div>
        )}

        {activeTab === "limit" && trade.trade_type !== "limit" && (
          <div className="text-lg">Trade type is not limit order</div>
        )}

        {/* Actions Tab Content */}
        {activeTab === "actions" && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-2">
                Actions Manage Position
              </label>
              <div className="grid grid-cols-2 gap-2">
                <button
                  onClick={() =>
                    setModalInputs({
                      ...modalInputs,
                      orderType: PendingOrderType.LIMIT_OPEN,
                    })
                  }
                  className={`px-4 py-2 rounded-md ${
                    modalInputs.orderType === PendingOrderType.LIMIT_OPEN
                      ? "bg-[#5b1dee] text-white"
                      : "bg-gray-100"
                  }`}
                >
                  Limit Open
                </button>
                <button
                  onClick={() =>
                    setModalInputs({
                      ...modalInputs,
                      orderType: PendingOrderType.STOP_OPEN,
                    })
                  }
                  className={`px-4 py-2 rounded-md ${
                    modalInputs.orderType === PendingOrderType.STOP_OPEN
                      ? "bg-[#5b1dee] text-white"
                      : "bg-gray-100"
                  }`}
                >
                  Stop Open
                </button>
                <button
                  onClick={() =>
                    setModalInputs({
                      ...modalInputs,
                      orderType: PendingOrderType.TP_CLOSE,
                    })
                  }
                  className={`px-4 py-2 rounded-md ${
                    modalInputs.orderType === PendingOrderType.TP_CLOSE
                      ? "bg-[#5b1dee] text-white"
                      : "bg-gray-100"
                  }`}
                >
                  Tp Close
                </button>
                <button
                  onClick={() =>
                    setModalInputs({
                      ...modalInputs,
                      orderType: PendingOrderType.SL_CLOSE,
                    })
                  }
                  className={`px-4 py-2 rounded-md ${
                    modalInputs.orderType === PendingOrderType.SL_CLOSE
                      ? "bg-[#5b1dee] text-white"
                      : "bg-gray-100"
                  }`}
                >
                  Sl Close
                </button>
                <button
                  onClick={() =>
                    setModalInputs({
                      ...modalInputs,
                      orderType: PendingOrderType.LIQ_CLOSE,
                    })
                  }
                  className={`px-4 py-2 rounded-md ${
                    modalInputs.orderType === PendingOrderType.LIQ_CLOSE
                      ? "bg-[#5b1dee] text-white"
                      : "bg-gray-100"
                  }`}
                >
                  Liq Close
                </button>
              </div>
            </div>

            <PurpleButton
              text={"Trigger Trade"}
              onClick={() => handleTriggerTrade()}
            />

            <RedButton
              text={"Close Trade"}
              onClick={() => handleCloseTrade()}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UpdateActionsModal;
