import React from "react";
import { Wifi,WorkJob,LaptopTable } from "../../Amenities/InternetAndOffice";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";


const INTERNET_OFFICE_AMENITIES = [
    { id: 'wifi', Icon: Wifi },
    { id: 'workjob', Icon: WorkJob },
    { id: 'laptoptable', Icon: LaptopTable }
];

const InternetAndOffice = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
        const currentAmenities = metaDetails.amenities["Internet and office"] || [];
        const isSelected = currentAmenities.includes(amenityId);
        
        setMetaDetails({
            ...metaDetails,
            amenities: {
                ...metaDetails.amenities,
                "Internet and office": isSelected
                    ? currentAmenities.filter(item => item !== amenityId)
                    : [...currentAmenities, amenityId]
            }
        });
    };

    return (
        <div className="grid grid-cols-2 gap-[10px]">
            {INTERNET_OFFICE_AMENITIES.map(({ id, Icon }) => (
                <div key={id} className="w-full items-center justify-between flex">
                    <Icon />
                    {metaDetails.amenities["Internet and office"]?.includes(id) ? (
                        <CheckMarkPurple onClick={() => toggleAmenity(id)} />
                    ) : (
                        <PlusIcon onClick={() => toggleAmenity(id)} />
                    )}
                </div>
            ))}
        </div>
    );
};

export default InternetAndOffice;