import React from "react";
import { SwimmingPool,ValleyView,Park,Balcony } from "../../Amenities/View";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";

const LOCATION_FEATURES = [
    { id: 'swimmingpool', Icon: SwimmingPool },
    { id: 'valleyview', Icon: ValleyView },
    { id: 'park', Icon: Park },
    { id: 'balcony', Icon: Balcony },
  ];

  const LocationFeatures = ({ metaDetails, setMetaDetails }) => {
    const toggleFeature = (featureId) => {
      const currentFeatures = metaDetails.amenities["Location features"] || [];
      const isSelected = currentFeatures.includes(featureId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          "Location features": isSelected
            ? currentFeatures.filter(item => item !== featureId)
            : [...currentFeatures, featureId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {LOCATION_FEATURES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities["Location features"]?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleFeature(id)} />
            ) : (
              <PlusIcon onClick={() => toggleFeature(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default LocationFeatures;