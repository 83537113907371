import axios from "axios";
import jwt from "jsonwebtoken";
import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || 'fallback-key-for-development';

const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  try {
    const decoded = jwt.decode(token);
    return decoded.userId;
  } catch (error) {
    console.error('Token decode error:', error);
    return null;
  }
};

export const setAuthToken = () => {
  const userId = getUserIdFromToken();
  const tokenData = {
    userId,
    timestamp: Date.now()
  };
  
  return "Bearer " + jwt.sign(
    tokenData, 
    process.env.REACT_APP_ACCESS_TOKEN_SECRET,
    { expiresIn: "1h" }
  );
};

export const encryptData = (data) => {
  try {
    if (!ENCRYPTION_KEY) {
      console.warn('Encryption key not found, sending data without encryption');
      return JSON.stringify(data);
    }
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      ENCRYPTION_KEY
    ).toString();
  } catch (error) {
    console.error('Encryption error:', error);
    return JSON.stringify(data); 
  }
};

export const api = async (route, data) => {
  try {
    const cacheKey = `${route}-${JSON.stringify(data)}`;
    const cachedResponse = sessionStorage.getItem(cacheKey);
    
    // Return cached data if available and not expired
    if (cachedResponse) {
      const { data: cachedData, timestamp } = JSON.parse(cachedResponse);
      const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
      
      if (Date.now() - timestamp < CACHE_DURATION) {
        return cachedData;
      }
    }

    const payload = data ? encryptData(data) : null;
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_SERVER + route,
      payload ? { payload } : {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: setAuthToken()
        }
      }
    );

    // Cache the response
    sessionStorage.setItem(cacheKey, JSON.stringify({
      data: response.data,
      timestamp: Date.now()
    }));

    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};
