import React from "react";
import { TrowelBrush,BedroomHanger,BedDouble,Bed,DresserDrawer,DressingMirror,SafeBox,SofaDouble,WashingMachine } from "../../Amenities/Bedroom";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";

const BEDROOM_LAUNDRY_AMENITIES = [
    { id: 'trowelbrush', Icon: TrowelBrush },
    { id: 'hanger', Icon: BedroomHanger },
    { id: 'beddouble', Icon: BedDouble },
    { id: 'bed', Icon: Bed },
    { id: 'dresserdrawer', Icon: DresserDrawer },
    { id: 'dressingmirror', Icon: DressingMirror },
    { id: 'safebox', Icon: SafeBox },
    { id: 'sofadouble', Icon: SofaDouble },
    { id: 'washingmachine', Icon: WashingMachine }
  ];
  
  const BedroomAndLaundry = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
      const currentAmenities = metaDetails.amenities["Bedroom and Laundry"] || [];
      const isSelected = currentAmenities.includes(amenityId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          "Bedroom and Laundry": isSelected
            ? currentAmenities.filter(item => item !== amenityId)
            : [...currentAmenities, amenityId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {BEDROOM_LAUNDRY_AMENITIES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities["Bedroom and Laundry"]?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleAmenity(id)} />
            ) : (
              <PlusIcon onClick={() => toggleAmenity(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default BedroomAndLaundry;