import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  SelectionGroup,
  SelectionItem,
} from "../../../Components/Selection/Selection";
import { LightIcon } from "../../../AssetComponents/Images";
import { SearchBox } from "../../../Components/Search/SearchBox";

import { DubaiCard } from "./RealEstates/Dubai/Card";
import { BTCCard } from "./Cryptos/BTC/Card";
import { AtomCard } from "./Cryptos/Atom/Card";
import { EthCard } from "./Cryptos/Eth/Card";

export const Markets = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentMarket, setCurrentMarket] = useState();
  useEffect(() => {
    switch (location.pathname.split("/")[3]) {
      case "realestate":
        setCurrentMarket(0);
        break;
      case "crypto":
        setCurrentMarket(1);
        break;
      case "forex":
        setCurrentMarket(2);
        break;
      case "commodities":
        setCurrentMarket(3);
        break;
      default:
        break;
    }
  }, [location]);

  return (
    <div className="my-[20px] mx-auto w-[80vw] p-[20px] rounded-[8px] font-normal">
      <div className="w-full flex items-center justify-between">
        <SelectionGroup
          className="px-[6px] w-max py-[4px] gap-[8px] flex items-center rounded-[14px] bg-[#f6f6f6] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
          defaultItem={currentMarket}
        >
          <SelectionItem
            SelectedItem={
              <div className="cursor-pointer bg-white rounded-[10px] w-[130px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                <div className="text-black font-medium">Real Estate</div>
                <LightIcon />
              </div>
            }
            UnselectedItem={
              <div
                onClick={() => navigate("/perps/markets/realestate")}
                className="cursor-pointer hover:bg-white rounded-[10px] w-[130px] flex justify-center"
              >
                <div className="text-[#959595]">Real Estate</div>
              </div>
            }
          />
          <SelectionItem
            SelectedItem={
              <div className="cursor-pointer bg-white rounded-[10px] w-[100px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                <div className="text-black font-medium">Crypto</div>
                <LightIcon />
              </div>
            }
            UnselectedItem={
              <div
                onClick={() => navigate("/perps/markets/crypto")}
                className="cursor-pointer hover:bg-white rounded-[10px] w-[100px] flex justify-center"
              >
                <div className="text-[#959595]">Crypto</div>
              </div>
            }
          />
        </SelectionGroup>
        <SearchBox />
      </div>
      <div className="mt-6">
        {currentMarket === 0 && (
          <>
            <DubaiCard />
          </>
        )}
        {currentMarket === 1 && (
          <div className="flex gap-4 items-center">
            <BTCCard />
            <AtomCard />
            <EthCard />
          </div>
        )}
      </div>
    </div>
  );
};
