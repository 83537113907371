import React from 'react';

export const PriceInput = ({ label, value, onChange }) => {
  return (
    <div className="w-full space-y-[10px]">
      <div className="font-semibold">{label}</div>
      <div className="flex gap-2 bg-white px-[12px] w-full py-[8px] border-[2px] border-[#E3E3E3] rounded-[12px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
        <div>USDC</div>
        <input
          className="outline-none w-full"
          type="number"
          min="1"
          max="1000"
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value) || 1)}
        />
      </div>
    </div>
  );
};