import Modal from "react-responsive-modal";

export const FilterModal = ({ open, onClose, children }) => {
  return (
    <Modal
      open={open}
      center
      onClose={onClose}
      classNames={{
        modal:
          "rounded-[8px] border-[1px] border-[#E3E3E3] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]",
      }}
    >
      <div className="w-[500px]">
        {children}
      </div>
    </Modal>
  );
};
