import React from "react";
import { ChildrenSlide,Lounge,Swing,Sun,BeachUmbrella } from "../../Amenities/Outdoor";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";

const OUTDOOR_AMENITIES = [
    { id: 'childrenslide', Icon: ChildrenSlide },
    { id: 'lounge', Icon: Lounge },
    { id: 'swing', Icon: Swing },
    { id: 'sun', Icon: Sun },
    { id: 'beachumbrella', Icon: BeachUmbrella }
  ];

  
const OutdoorAmenities = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
      const currentAmenities = metaDetails.amenities["Outdoor"] || [];
      const isSelected = currentAmenities.includes(amenityId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          "Outdoor": isSelected
            ? currentAmenities.filter(item => item !== amenityId)
            : [...currentAmenities, amenityId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {OUTDOOR_AMENITIES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities["Outdoor"]?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleAmenity(id)} />
            ) : (
              <PlusIcon onClick={() => toggleAmenity(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default OutdoorAmenities;