import React from 'react'

function ChartSelected() {
  return (
    <div>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.1665 13.2409L8.39234 9.01504C8.71817 8.68921 9.24567 8.68921 9.57067 9.01504L11.3548 10.7992C11.6807 11.125 12.2082 11.125 12.5332 10.7992L17.4998 5.83337" stroke="#202020" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.2773 5.83337H17.4998V8.05587" stroke="#202020" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.4998 17.4409H1.604V2.6842" stroke="#202020" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    </div>
  )
}

export default ChartSelected