import { FilterIconRent } from "../../AssetComponents/Images";

export const FilterButton = ({ onClick }) => (
    <div
      onClick={onClick}
      className="py-[4px] cursor-pointer bg-white hover:bg-[#f6f6f6] rounded-[10px] px-[14px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
    >
      <FilterIconRent />
      <div className="text-black font-semibold">Filter</div>
    </div>
  );
  