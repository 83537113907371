import React from "react";
import { CookwareKitchenUtensils,DryingRack,CableTv,Toaster,Wardrobe,DiningTable,Reception,CityView,SmokeDetectors,MarinaView,Gym,CarbonMonoxideDetector,Hangers,TrashCans,WineGlasses,StreamingServiceSuchAsNetflix,CrockeryCutlery,Toilet,Oven,CoffeeMaker,ComplimentarySoapShampooConditioner,BeachView,Elevator,WirelessInternet,FreeParkingWithGarage,SmartTv,Marina,RoomDarkeningShades,IronIroningBoard,BedLinenTowels,Kettle,Microwave,AirConditioning,Seaview,Beach,PlayGround,Refrigerator,FamilyKidsFriendly,NoParties,Essentials,CleaningProducts,FirstAidKit,CookingBasics,SmokingNotAllowed } from "../../Amenities/Others";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";
import { FireExtinguisher } from "../../Amenities/HomeSafety";

const OTHERS_AMENITIES = [
    { id: 'CookwareKitchenUtensils', Icon: CookwareKitchenUtensils },
    { id: 'DryingRack', Icon: DryingRack },
    { id: 'CableTv', Icon: CableTv },
    { id: 'Toaster', Icon: Toaster },
    { id: 'Wardrobe', Icon: Wardrobe },
    { id: 'DiningTable', Icon: DiningTable },
    { id: 'Reception', Icon: Reception },
    { id: 'CityView', Icon: CityView },
    { id: 'SmokeDetectors', Icon: SmokeDetectors },
    { id: 'MarinaView', Icon: MarinaView },
    { id: 'Gym', Icon: Gym },
    { id: 'CarbonMonoxideDetector', Icon: CarbonMonoxideDetector },
    { id: 'Hangers', Icon: Hangers },
    { id: 'TrashCans', Icon: TrashCans },
    { id: 'WineGlasses', Icon: WineGlasses },
    { id: 'StreamingServiceSuchAsNetflix', Icon: StreamingServiceSuchAsNetflix },
    { id: 'CrockeryCutlery', Icon: CrockeryCutlery },
    { id: 'Toilet', Icon: Toilet },
    { id: 'Oven', Icon: Oven },
    { id: 'CoffeeMaker', Icon: CoffeeMaker },
    { id: 'ComplimentarySoapShampooConditioner', Icon: ComplimentarySoapShampooConditioner },
    { id: 'BeachView', Icon: BeachView },
    { id: 'Elevator', Icon: Elevator },
    { id: 'WirelessInternet', Icon: WirelessInternet },
    { id: 'FreeParkingWithGarage', Icon: FreeParkingWithGarage },
    { id: 'SmartTv', Icon: SmartTv },
    { id: 'FireExtinguisher', Icon: FireExtinguisher },
    { id: 'Marina', Icon: Marina },
    { id: 'RoomDarkeningShades', Icon: RoomDarkeningShades },
    { id: 'IronIroningBoard', Icon: IronIroningBoard },
    { id: 'BedLinenTowels', Icon: BedLinenTowels },
    { id: 'Kettle', Icon: Kettle },
    { id: 'Microwave', Icon: Microwave },
    { id: 'AirConditioning', Icon: AirConditioning },
    { id: 'Seaview', Icon: Seaview },
    { id: 'Beach', Icon: Beach },
    { id: 'PlayGround', Icon: PlayGround },
    { id: 'Refrigerator', Icon: Refrigerator },
    { id: 'FamilyKidsFriendly', Icon: FamilyKidsFriendly },
    { id: 'NoParties', Icon: NoParties },
    { id: 'Essentials', Icon: Essentials },
    { id: 'CleaningProducts', Icon: CleaningProducts },
    { id: 'FirstAidKit', Icon: FirstAidKit },
    { id: 'CookingBasics', Icon: CookingBasics },
    { id: 'SmokingNotAllowed', Icon: SmokingNotAllowed },
  ];
  
  const Others = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
      const currentAmenities = metaDetails.amenities.Others || [];
      const isSelected = currentAmenities.includes(amenityId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          Others: isSelected
            ? currentAmenities.filter(item => item !== amenityId)
            : [...currentAmenities, amenityId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {OTHERS_AMENITIES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities.Others?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleAmenity(id)} />
            ) : (
              <PlusIcon onClick={() => toggleAmenity(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default Others;
