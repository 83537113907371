import { SelectionGroup,SelectionItem } from "../Selection/Selection";
import { setPropertyType } from "../../ReduxSlices/SearchSlice";
export const PropertyTypeFilter = ({ propertyType, dispatch }) => {
    const propertyTypes = ['House', 'Apartment', 'Guesthouse', 'Hotel'];
    
    return (
      <div>
        <div className="text-[18px] font-semibold">Property type</div>
        <SelectionGroup
          defaultItem={propertyType}
          className="flex gap-[8px] text-[#D5D5D5] my-[12px]"
        >
          {propertyTypes.map((type, index) => (
            <SelectionItem
              key={type}
              SelectedItem={
                <div className="bg-[#F6F6F6] min-w-[100px] hover:bg-[#ffffff] w-full py-[30px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                  {type}
                </div>
              }
              UnselectedItem={
                <div
                  className="bg-[#F6F6F6] min-w-[100px] hover:bg-[#ffffff] w-full py-[30px] border-[1px] rounded-[16px] text-center"
                  onClick={() => dispatch(setPropertyType(index))}
                >
                  {type}
                </div>
              }
            />
          ))}
        </SelectionGroup>
      </div>
    );
  };
  