import React from "react";
import { CloseIcon } from "../../../AssetComponents/Images";
import { formatNumber } from "../../utils/formatter";
import { useDispatch, useSelector } from "react-redux";
import { Testnet } from "@nibiruchain/nibijs";
import { toast } from "react-toastify";
import { executePerps } from "../../functions/Contract";
import { useState } from "react";
import UpdateActionsModal from "../Modals/UpdateActionsModal";

const OrdersRow = ({ trade }) => {
  const account = useSelector((state) => state.auth.account);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  // Only show if it's a limit order AND is still open
  if (trade.trade_type !== "limit" || !trade.is_open) {
    return null;
  }

  const handleCancelLimitOrder = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const message = {
        cancel_open_limit_order: {
          trade_index: trade.user_trade_index,
        },
      };

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap"
      );

      if (!res) {
        throw new Error("Transaction failed");
      }

      toast.success("Trade closed successfully!");

      return res;
    } catch (err) {
      console.error("Close trade error:", err);
      toast.error(`Failed to close trade: ${err.message}`);
      return false;
    }
  };

  const getMarketName = (marketIndex) => {
    const markets = {
      "MarketIndex(0)": "BTC/USDC",
      "MarketIndex(1)": "ETH/USDC",
      "MarketIndex(2)": "ATOM/USDC",
      "MarketIndex(3)": "DUBAI/USDC",
    };
    return markets[marketIndex] || "UNKNOWN/USDC";
  };

  const formatDate = (timestamp) => {
    try {
      // Convert nanoseconds to milliseconds
      let milliseconds = timestamp / 1e6;

      // Create a Date object
      let date = new Date(milliseconds);

      // Extract the time components and format them
      let hours = String(date.getHours()).padStart(2, "0");
      let minutes = String(date.getMinutes()).padStart(2, "0");

      // Extract the date components and format them
      let month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is 0-indexed
      let day = String(date.getDate()).padStart(2, "0");
      let year = date.getFullYear();

      // Output in the desired format: hh:mm MM/DD/YYYY
      let formattedDate = `${hours}:${minutes} ${month}/${day}/${year}`;

      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error, timestamp);
      return "Format Error";
    }
  };

  return (
    <>
      <div
        key={trade.user_trade_index}
        onClick={() => setModalShow(true)}
        className="grid grid-cols-6 gap-2 py-3 border-b border-gray-200 hover:bg-gray-50 cursor-pointer text-sm lg:text-sm"
      >
        {/* Date */}
        <div className="flex items-center justify-center px-1 text-sm">
          {formatDate(trade.open_timestamp)}
        </div>

        {/* Type - Simple BUY or SELL */}
        <div
          className={`flex items-center justify-center px-1 text-sm font-medium ${
            trade.long ? "text-green-500" : "text-red-500"
          }`}
        >
          {trade.long ? "BUY" : "SELL"}
        </div>

        {/* Pair */}
        <div className="flex items-center justify-center px-1 font-medium text-sm">
          {getMarketName(trade.market_index)}
        </div>

        {/* Size */}
        <div className="flex items-center justify-center px-1 text-sm font-medium">
          ${((Number(trade.collateral_amount) * Number(trade.leverage)) / 1000000).toFixed(2)}
        </div>

        {/* Price */}
        <div className="flex items-center justify-center px-1 text-sm font-medium">
          {formatNumber(Number(trade.open_price), { currency: true })}
        </div>

        {/* Actions */}
        <div className="flex items-center justify-center gap-1.5">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setModalShow(true);
            }}
            className="bg-green-500 hover:bg-green-600 text-white rounded px-2 py-1 text-sm"
          >
            Edit
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleCancelLimitOrder();
            }}
            className="bg-red-500 hover:bg-red-600 text-white rounded px-2 py-1 text-sm"
          >
            Close
          </button>
        </div>
      </div>
      <UpdateActionsModal
        isOpen={modalShow}
        setIsOpen={setModalShow}
        trade={trade}
      />
    </>
  );
};

export default OrdersRow;