import React from 'react'

function NFT() {
  return (
    <div>   <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="9.9987"
      cy="5.41667"
      rx="2.91667"
      ry="2.91667"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.2487 11.6667V8.05243"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.7487 8.05243V11.6667"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.33203 11.6667H11.6654C13.0461 11.6667 14.1654 12.7859 14.1654 14.1667V14.1667H5.83203V14.1667C5.83203 12.7859 6.95132 11.6667 8.33203 11.6667V11.6667Z"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16667 14.1667H15.8333C16.7538 14.1667 17.5 14.9128 17.5 15.8333V16.6667C17.5 17.1269 17.1269 17.5 16.6667 17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V15.8333C2.5 14.9128 3.24619 14.1667 4.16667 14.1667Z"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg></div>
  )
}

export default NFT