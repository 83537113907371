import { queryContract } from "./Contract";
import { updateNFTStore } from "../../ReduxSlices/NFTSlice";
import { api } from "./Api";
import { createThirdwebClient } from "thirdweb";

export const thiredWebClient = createThirdwebClient({
  clientId: "abdfecae24f5a6170f2a52fa803ca761",
});

export const truncateWalletAddress = (address, forward = 5, backward = 4) => {
  if (address)
    return (
      address.toString().substring(0, forward) +
      "..." +
      address.toString().substring(address.toString().length - backward)
    );
};


const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

const retryOperation = async (operation, retries = MAX_RETRIES, delay = RETRY_DELAY) => {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0) {
      await new Promise(resolve => setTimeout(resolve, delay));
      return retryOperation(operation, retries - 1, delay);
    }
    throw error;
  }
};


export const updateToken = async (data, endpt, dispatch) => {
  try {
    const [res, short, rentals] = await Promise.all([
      retryOperation(() => queryContract(
        data.contract,
        { all_nft_info: { token_id: data.token_id } },
        endpt
      )),
      retryOperation(() => queryContract(
        data.contract,
        { nft_info_short_term_rental: { token_id: data.token_id } },
        endpt
      )),
      retryOperation(() => queryContract(
        data.contract,
        { nft_rentals: { token_id: data.token_id } },
        endpt
      ))
    ]);

    const metaData = await retryOperation(() => 
      api("property/getProperty", { token_id: data.token_id })
    );

    
    if (res?.access && short) {
      await retryOperation(() => 
        api("property/updateProperty", {
          token_id: data.token_id,
          short: {
            ...short,
            price_per_day: short?.price_per_day / 10 ** process.env.REACT_APP_USDC_DECIMALS,
          },
          rentals: rentals,
          access: res?.access,
        })
      );
    }

    dispatch(
      updateNFTStore({
        token_id: data.token_id,
        metaData: metaData?.metaData,
        short: {
          ...short,
          price_per_day: short?.price_per_day / 10 ** process.env.REACT_APP_USDC_DECIMALS,
        },
        rentals: rentals,
        access: res?.access,
        error: null
      })
    );
  } catch (error) {
    console.error(`Error updating token ${data.token_id}:`, error);
    dispatch(
      updateNFTStore({
        token_id: data.token_id,
        error: 'Failed to load property data'
      })
    );
  }
};

export const getTokensFromBackend = async (account) => {
  try {
    const response = await retryOperation(() => 
      api("property/getProperties", {
        walletAddress: account.toLowerCase()
      })
    );
    return response.properties || [];
  } catch (error) {
    console.error("Error fetching properties from backend:", error);
    return [];
  }
};

export const getTokens = async (account, endpt, dispatch) => {
  try {

    const properties = await getTokensFromBackend(account);
    
    
    const batchSize = 10;
    for (let i = 0; i < properties.length; i += batchSize) {
      const batch = properties.slice(i, i + batchSize);
      
      batch.forEach(property => {
        dispatch(
          updateNFTStore({
            token_id: property.token_id,
            metaData: property.metaData,
            short: {
              ...property.short,
              price_per_day: property.short?.price_per_day 
                ? property.short.price_per_day / 10 ** process.env.REACT_APP_USDC_DECIMALS 
                : 0
            },
            rentals: property.rentals,
            access: property.access,
            isInitialLoad: true
          })
        );
      });


      await new Promise(resolve => setTimeout(resolve, 50));
    }

    
    if (properties.length > 0) {
      setTimeout(() => {
        updateFromBlockchain(properties, endpt, dispatch);
      }, 100);
    }

  } catch (error) {
    console.error("Error in getTokens:", error);
  }
};

const updateFromBlockchain = async (properties, endpt, dispatch) => {
  try {
    for (const property of properties) {
      await updateToken(
        {
          token_id: property.token_id,
          contract: process.env.REACT_APP_RENTAL_SMART_CONTRACT,
        },
        endpt,
        dispatch
      );
    }
  } catch (error) {
    console.error("Error updating from blockchain:", error);
  }
};

export const addToken = async (account, token_id, endpt, dispatch) => {
  const res = await api("user/addTokens", {
    account: account,
    token_id: token_id,
  });
  getTokens(account, endpt, dispatch);
};

export const getProfileFromWallet = async (wallet) => {
  const profile = await api("profile/getProfile", {
    walletAddress: wallet,
  });
  return profile;
};

export const getTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const timeString = hours + ":" + minutes + " " + amOrPm;
  return timeString;
};

export const getDay = (date) => {
  try {
    const monthString = new Intl.DateTimeFormat("en-US", {
      month: "short",
    }).format(date);

    const day = ("0" + date.getDate()).slice(-2);
    let formattedDate = monthString + " " + day;
    const currentTime = new Date();
    if (
      currentTime.getFullYear() +
        currentTime.getMonth() +
        currentTime.getDate() ===
      date.getFullYear() + date.getMonth() + date.getDate()
    )
      return "Today";

    return formattedDate;
  } catch (error) {
    return null;
  }
};

export const getChatId = async (renter, owner, nftId, mode) => {
  return await api("chat/getChatId", {
    renter: renter,
    owner: owner,
    nftId: nftId,
    mode: mode,
  });
};

export const getIdFromHash = async (hash) => {
  return await api("tx/fetchId", {
    hash: hash,
  });
};

const BATCH_SIZE = 10; 
const BATCH_DELAY = 2000; 

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const syncAllNFTs = async (endpt, dispatch) => {
  try {
    const message = {
      all_token_ids: {}
    };
    
    const allTokens = await retryOperation(() => 
      queryContract(
        process.env.REACT_APP_RENTAL_SMART_CONTRACT,
        message,
        endpt
      )
    );

    console.log('Found tokens:', allTokens?.token_ids?.length || 0);
    
    if (allTokens?.token_ids) {
    
      for (let i = 0; i < allTokens.token_ids.length; i += BATCH_SIZE) {
        const batch = allTokens.token_ids.slice(i, i + BATCH_SIZE);
        
     
        const updatePromises = batch.map(token_id => 
          updateToken(
            {
              token_id: token_id,
              contract: process.env.REACT_APP_RENTAL_SMART_CONTRACT,
            },
            endpt,
            dispatch,
            true
          ).catch(error => {
            console.error(`Failed to update token ${token_id}:`, error);
            return null; 
          })
        );

        await Promise.all(updatePromises);
        console.log(`Processed batch ${i/BATCH_SIZE + 1}/${Math.ceil(allTokens.token_ids.length/BATCH_SIZE)}`);
      
        if (i + BATCH_SIZE < allTokens.token_ids.length) {
          await sleep(BATCH_DELAY);
        }
      }
      console.log('All tokens synced successfully');
    }

    return true;
  } catch (error) {
    console.error('Error syncing NFTs:', error);
    return false;
  }
};
