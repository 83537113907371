import { setInstantBook, setAllowPets } from "../../ReduxSlices/SearchSlice";

export const BookingOptions = ({ instantBook, allowPets, dispatch }) => {
    return (
      <div className="space-y-[12px]">
        <div className="text-[18px] font-semibold">Booking Options</div>
        <div className="flex items-center justify-between">
          <div>
            <div className="font-medium">Instant Book</div>
            <div className="text-[14px] text-[#717171]">
              Book without waiting for host approval
            </div>
          </div>
          <button
            onClick={() => dispatch(setInstantBook(!instantBook))}
            className={`${
              instantBook ? 'bg-[#5b1dee]' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors`}
          >
            <span 
              className={`
                ${instantBook ? 'translate-x-6' : 'translate-x-1'}
                inline-block w-4 h-4 transform bg-white rounded-full transition-transform
              `}
            />
          </button>
        </div>
        <div className="flex items-center justify-between">
          <div>
            <div className="font-medium">Allows Pets</div>
            <div className="text-[14px] text-[#717171]">
              Properties that welcome pets
            </div>
          </div>
          <button
            onClick={() => dispatch(setAllowPets(!allowPets))}
            className={`${
              allowPets ? 'bg-[#5b1dee]' : 'bg-gray-200'
            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors`}
          >
            <span 
              className={`
                ${allowPets ? 'translate-x-6' : 'translate-x-1'}
                inline-block w-4 h-4 transform bg-white rounded-full transition-transform
              `}
            />
          </button>
        </div>
      </div>
    );
  };
