import React from 'react'

function Slash() {
  return (
    <div>   <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" fill="#E3E3E3" />
    <path
      d="M11.4837 6.38636L9.13991 15.0938H8.19176L10.5355 6.38636H11.4837Z"
      fill="#202020"
    />
  </svg></div>
  )
}

export default Slash