import React from "react";
import { SelectionGroup,SelectionItem } from "../Selection/Selection";
import { setAmenities } from "../../ReduxSlices/SearchSlice";

export const BedroomFilter = ({ amenities, dispatch }) => {
    return (
      <div>
        <div>Bedrooms</div>
        <SelectionGroup
          defaultItem={amenities.bedroom}
          className="flex gap-[8px] text-[#D5D5D5] my-[12px]"
        >
          {['Any', '1', '2', '3', '4', '5', '6', '7+'].map((value, index) => (
            <SelectionItem
              key={value}
              SelectedItem={
                <div className="w-full min-w-[50px] py-[8px] border-[1px] rounded-[16px] text-center border-[#5B1DEE] text-[#5B1DEE]">
                  {value}
                </div>
              }
              UnselectedItem={
                <div
                  className="w-full min-w-[50px] py-[8px] border-[1px] rounded-[16px] text-center"
                  onClick={() =>
                    dispatch(setAmenities({ ...amenities, bedroom: index }))
                  }
                >
                  {value}
                </div>
              }
            />
          ))}
        </SelectionGroup>
      </div>
    );
  };