
import React from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { CustomChart } from '../../Components/Chart/CustomChart';
import { PriceInput } from './FilterPriceInput';

export const PriceRangeSection = ({ range, onRangeChange }) => {
  const chartData = [
    7, 22, 1, 93, 48, 8, 67, 9, 55, 7, 22, 1, 93, 48, 8, 67, 9, 55, 7, 22, 1,
    93, 48, 8, 67, 9, 55,
  ];

  const handleRangeChange = ([min, max]) => {
    onRangeChange({
      min: Math.max(1, Math.min(min, max)),
      max: Math.min(1000, Math.max(min, max))
    });
  };

  const handleMinChange = (newMin) => {
    handleRangeChange([newMin, range.max]);
  };

  const handleMaxChange = (newMax) => {
    handleRangeChange([range.min, newMax]);
  };

  return (
    <div>
      <div className="mt-[20px]">
        <div className="text-[18px] font-semibold">Price range</div>
      </div>
      <div className="w-full">
        <div className="w-full mx-auto space-y-2">
          <CustomChart
            data={chartData}
            start={range.min}
            end={range.max}
            min={1}
            max={1000}
          />
          <RangeSlider
            max={1000}
            min={1}
            value={[range.min, range.max]}
            onInput={handleRangeChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[20px] my-[16px] font-semibold">
        <PriceInput
          label="Minimum"
          value={range.min}
          onChange={handleMinChange}
        />
        <PriceInput
          label="Maximum"
          value={range.max}
          onChange={handleMaxChange}
        />
      </div>
    </div>
  );
};