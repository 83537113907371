import React from "react";
import { NUSDIcon } from "../../../AssetComponents/Images";
import { formatNumber } from "../../../Components/utils/formatter";

// Updated helper to ensure numStr is a string
const formatWithComma = (numStr) => {
  const str = numStr.toString();
  if (str === "" || str === ".") return str;
  const parts = str.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

const DepositAmount = ({
  amount,
  setAmount,
  tokenBalance,
  setAvailableAmount,
}) => {
  return (
    <div className="space-y-2">
      <div className="flex justify-between">
        <div className="text-sm text-gray-500">Deposit Amount</div>
        <div className="text-sm text-gray-500">
          Balance: {formatNumber(tokenBalance)} USDC
        </div>
      </div>

      <div className="flex items-center justify-between p-3 pr-6 bg-gray-50 rounded-lg">
        <input
          type="text"
          value={amount ? formatWithComma(String(amount)) : amount}
          onChange={(e) => {
            const rawVal = e.target.value.replace(/,/g, ""); // remove commas

            // Always allow empty string - this is key to fix the issue
            if (rawVal === "") {
              setAmount("");
              setAvailableAmount("0");
              return;
            }

            // Allow decimal point during typing
            if (rawVal === ".") {
              setAmount("0.");
              setAvailableAmount("0");
              return;
            }

            // Only allow numeric input with decimal
            if (!/^\d*\.?\d*$/.test(rawVal)) return;

            // Direct input - no conversion
            setAmount(rawVal);

            // Calculate percentage for UI updates
            const numericVal = parseFloat(rawVal);
            if (!isNaN(numericVal) && tokenBalance > 0) {
              const pct = (numericVal / tokenBalance) * 100;
              setAvailableAmount(pct.toFixed(2));
            } else {
              setAvailableAmount("0");
            }
          }}
          onBlur={() => {
            const rawVal = String(amount).replace(/,/g, "");
            if (rawVal === "") {
              setAvailableAmount("0");
              return;
            }

            if (rawVal === ".") {
              setAmount("0");
              setAvailableAmount("0");
              return;
            }

            const parsed = parseFloat(rawVal);
            if (isNaN(parsed)) {
              // Keep it empty if it's invalid
              setAmount("");
              setAvailableAmount("0");
            } else {
              // Ensure the raw number is passed while UI continues to show formatted value
              setAmount(rawVal);

              if (tokenBalance > 0) {
                const pct = (parsed / tokenBalance) * 100;
                setAvailableAmount(pct.toFixed(2));
              }
            }
          }}
          placeholder="0.00"
          className="bg-transparent outline-none text-lg font-medium w-full"
        />

        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              // Set to maximum available balance
              setAmount(tokenBalance.toString());
              setAvailableAmount("100");
            }}
            className="px-2 py-1 text-sm font-medium text-gray-600 hover:text-[#5b1dee]"
          >
            MAX
          </button>
          <div className="flex items-center gap-2 text-gray-700">
            <NUSDIcon className="w-4 h-4" />
            <span>USDC</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositAmount;
