import { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTokens } from "../../../Components/functions/Functions";
import {
  SelectionGroup,
  SelectionItem,
} from "../../../Components/Selection/Selection";
import { EmptyNft, LightIcon } from "../../../AssetComponents/Images";
import { RentalItem } from "../../../Components/RealEstateProperty/RentalItem";
import { useLocation, useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import Home from "../../../Components/Host/Home";
import Building from "../../../Components/Host/Building";
import NFT from "../../../Components/Host/NFT";
import Rental from "../../../Components/Host/Rental";
import Search from "../../../Components/Host/Search";
import Slash from "../../../Components/Host/Slash";

export const HostNFTs = () => {
  const dispatch = useDispatch();
  const nfts = useSelector((state) => state.nft.nfts);
  const account = useSelector((state) => state.auth.account);
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isBackgroundUpdating, setIsBackgroundUpdating] = useState(false);

  useEffect(() => {
    const loadNFTs = async () => {
      if (account) {
        setIsLoading(true);
        try {
          await getTokens(account, process.env.REACT_APP_TESTNET_CHAIN_ID, dispatch);
        } catch (error) {
          console.error("Error loading NFTs:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadNFTs();
  }, [account, dispatch]);

  const { listedCounts, verifiedCounts, rentalActiveCounts } = useMemo(() => {
    let listed = 0, verified = 0, rentalActive = 0;
    const nftArray = Object.values(nfts);
    
    for(const nft of nftArray) {
      if(nft.access?.owner === account) {
        verified++;
        if(nft.short?.islisted) listed++;
        if(nft.rentals?.rentals?.length) rentalActive++;
      }
    }
    
    return {
      listedCounts: listed,
      verifiedCounts: verified,
      rentalActiveCounts: rentalActive
    };
  }, [nfts, account]);

  const filteredNFTs = useMemo(() => {
    return Object.keys(nfts).filter(nftId => {
      const nft = nfts[nftId];
      return nft.access?.owner === account && 
        ((current === 1 && nft.short?.islisted) || 
         (current === 0 && !nft.short?.islisted));
    });
  }, [nfts, account, current]);

  useEffect(() => {
    if (nfts && Object.keys(nfts).length > 0) {
      setIsLoading(false);
    }
  }, [nfts]);

  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-80px)] h-full p-[10px]">
      <div className="w-full h-full space-y-[20px] rounded-[10px] p-[8px] flex flex-col">
        <div className="bg-white border-[2px] h-max border-[#E3E3E3] p-[24px] rounded-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
          <div className="flex items-center gap-[10px]">
            <div className="p-[8px] w-[32px] h-[32px] flex items-center justify-center rounded-full bg-[#5b1dee]">
             <Home/>
            </div>

            <div className="font-semibold text-[24px]">My Real Estate NFTs</div>
          </div>

          <div className="text-[#666666] font-normal">
            Your Real Estate Status
          </div>
        </div>
        <div className="w-full grid grid-cols-4 gap-[16px]">
          <Fade cascade damping={0.2}>
            <div className="bg-white flex justify-between items-center p-[16px] rounded-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
              <div className="flex items-center gap-[10px]">
                <div className="p-[6px] rounded-full shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                <Building/>
                </div>
                <div className="text-[#959595]">Total NFTs Listed</div>
              </div>
              <div className="text-[20px]">{listedCounts}</div>
            </div>
           
            <div className="bg-white flex justify-between items-center p-[16px] rounded-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
              <div className="flex items-center gap-[10px]">
                <div className="p-[6px] rounded-full shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
               <NFT/>
                </div>
                <div className="text-[#959595]">Verified NFTS</div>
              </div>
              <div className="text-[20px]">{verifiedCounts}</div>
            </div>
            <div className="bg-white flex justify-between items-center p-[16px] rounded-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
              <div className="flex items-center gap-[10px]">
                <div className="p-[6px] rounded-full shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                 <Rental/>
                </div>
                <div className="text-[#959595]">Active Rentals</div>
              </div>
              <div className="text-[20px]">{rentalActiveCounts}</div>
            </div>
          </Fade>
        </div>
        <div className="px-[20px] w-full justify-between flex items-center my-[40px]">
          <SelectionGroup
            defaultItem={current}
            className="border-[2px] border-[#e3e3e3] w-max px-[6px] py-[4px] gap-[8px] flex items-center rounded-[14px] bg-white shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
          >
            <SelectionItem
              SelectedItem={
                <div className="py-[4px] cursor-pointer bg-white rounded-[10px] w-[160px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                  <div className="text-black font-semibold">Verified NFTs</div>
                  <LightIcon />
                </div>
              }
              UnselectedItem={
                <div
                  onClick={() => setCurrent(0)}
                  className="py-[4px] cursor-pointer hover:bg-[#f6f6f6] rounded-[10px] w-[160px] flex justify-center"
                >
                  <div className="text-[#959595]">Verified NFTs</div>
                </div>
              }
            />
           
            <SelectionItem
              SelectedItem={
                <div className="py-[4px] cursor-pointer bg-white rounded-[10px] w-[160px] flex justify-center gap-[10px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
                  <div className="text-black font-semibold">My Listings</div>
                  <LightIcon />
                </div>
              }
              UnselectedItem={
                <div
                  onClick={() => setCurrent(1)}
                  className="py-[4px] cursor-pointer hover:bg-[#f6f6f6] rounded-[10px] w-[160px] flex justify-center"
                >
                  <div className="text-[#959595]">My Listings</div>
                </div>
              }
            />
          </SelectionGroup>

          <div className="flex gap-[10px] items-center border-[#E3E3E3] border-[1px] rounded-[12px] pl-[16px] pr-[8px] py-[9px] bg-white shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
            
            <Search/>
            <input placeholder="Search" className="w-full outline-none" />
          <Slash/>
          </div>
        </div>

        <div className="flex px-[40px] gap-[20px] w-full flex-wrap overflow-auto max-h-[calc(100vh-200px)]">
          {isLoading ? (
            <div className="flex flex-col items-center w-full justify-center h-[calc(100vh-600px)]">
              <div>Loading NFTs...</div>
            </div>
          ) : (
            filteredNFTs.map((nftId) => (
              <Fade key={`nft-fade-${nftId}`}>
                <RentalItem key={`rental-${nftId}`} token_id={nftId} />
              </Fade>
            ))
          )}
          {!isLoading && filteredNFTs.length === 0 && (
            <div className="flex flex-col items-center w-full justify-center h-[calc(100vh-600px)]">
              <EmptyNft />
              <div>No NFTs yet</div>
              <div className="text-[#737373]">
                {current === 0
                  ? "No Verified NFTs yet"
                  : current === 1

                  ? "No Listed NFTs yet"
                  : "No NFTs yet"}
              </div>
            </div>
          )}
        </div>
        {isBackgroundUpdating && (
          <div className="fixed bottom-4 right-4 text-sm text-gray-500">
            Updating latest blockchain data...
          </div>
        )}
      </div>
    </div>
  );
};
