import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPrice: 0,
  update: false,
};

export const TradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {
    setCurrentPrice: (state, action) => {
      state.currentPrice = action.payload;
    },
    setPerpsUpdate: (state, action) => {
      state.update = !state.update;
    },
  },
});

export const { setCurrentPrice, setPerpsUpdate } = TradeSlice.actions;

export default TradeSlice.reducer;
