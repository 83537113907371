import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateLiquidationPrice } from "../../ReduxSlices/LiquidationSlice";
import { NUSDIcon, ArrowToDownHeader } from "../../AssetComponents/Images";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  executePerps,
  queryBalance,
} from "../../Components/functions/Contract";
import { Testnet } from "@nibiruchain/nibijs";
import TradeTabs from "./TradeFormComponents/TabsComponent";
import TradeDirection from "./TradeFormComponents/TradeDirection";
import DepositAmount from "./TradeFormComponents/DepositAmountComponent";
import TPSLSection from "./TradeFormComponents/TakeProfitStopLoss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { queryContract } from "../../Components/functions/Contract";
// import { handleLongOpenTrade } from "../../Pages/Trade/Componenets/Utils/TradingUtils";

// Example function to get potential PnL difference
function getPnLDiff(entryPrice, targetPrice, isLong) {
  const e = parseFloat(entryPrice);
  const t = parseFloat(targetPrice);
  if (isNaN(e) || isNaN(t)) return 0;
  return isLong ? t - e : e - t;
}

// Helper to compute percentage difference based on entry price
function getPnLPercent(entryPrice, targetPrice, isLong) {
  const diff = getPnLDiff(entryPrice, targetPrice, isLong);
  const parsedEntry = parseFloat(entryPrice);
  if (isNaN(diff) || isNaN(parsedEntry) || parsedEntry === 0) return 0;
  return (diff / parsedEntry) * 100;
}

// Add helper to format numbers with thousand separators and fixed 2 decimals
const formatNumber = (num) => {
  if (num == null || isNaN(num)) return "0.00";
  return Number(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const TradingForm = ({
  tokenIndex,
  marketIndex,
  // logScale,
  // labels,
  // handleChange,
  // leverageMarks,
  // isManualTPSL,
  // setIsManualTPSL,
  // handleLongOpenTrade,
  // handleLongLimitTrade,
  // handleShortOpenTrade,
  // handleShortLimitTrade,
  // limitPrice,
  // slippage,
  // setSlippage,
  // isAutoSlippage,
  // setIsAutoSlippage,
  // openPrice,
}) => {
  const account = useSelector((state) => state.auth.account);
  const perpsUpdate = useSelector((state) => state.trade.update);

  const [tab, setTab] = useState(0);
  const [longShort, setLongShort] = useState(0);
  const [amount, setAmount] = useState(100);
  const [availableAmount, setAvailableAmount] = useState(0);
  const [leverage_value, setLeverageValue] = useState(1.0);

  const [tokenBalance, setTokenBalance] = useState(0);
  const [isLoadingBalance, setIsLoadingBalance] = useState(true);
  const [tp, setTp] = useState(null);
  const [sl, setSl] = useState(null);
  const [tpPercentInput, setTpPercentInput] = useState("");
  const [slPercentInput, setSlPercentInput] = useState("");
  const [openPrice, setOpenPrice] = useState(0);
  const [isManualTPSL, setIsManualTPSL] = useState(false);
  const currentPrice = useSelector((state) => state.trade.currentPrice);

  const [slippage, setSlippage] = useState(0.5);
  const [isAutoSlippage, setIsAutoSlippage] = useState(true);

  // const currentPrice = useSelector((state) => state.liquidation.currentPrice);
  const dispatch = useDispatch();
  const { liquidationPrice, currentLeverage } = useSelector(
    (state) => state.liquidation
  );
  const [lastWarningTime, setLastWarningTime] = useState(0);

  const slippagePresets = [
    { value: 0.1, label: "0.1%" },
    { value: 0.5, label: "0.5%" },
    { value: 1, label: "1%" },
    { value: 2, label: "2%" },
  ];

  // Function to fetch balance, can be called after transactions
  const fetchBalance = async () => {
    if (account) {
      try {
        setIsLoadingBalance(true);
        const balance = await queryBalance(
          account,
          process.env.REACT_APP_COLLATERAL_DENOM,
          Testnet(2).endptTm
        );
        // Format the balance properly - always use toFixed(2)
        const amountValue = Number(balance.amount) / 1e6;
        console.log("Raw balance:", balance.amount);
        console.log("Formatted balance:", amountValue.toFixed(2));
        setTokenBalance(Number(amountValue.toFixed(2)));
      } catch (err) {
        console.error("Failed to get balance:", err);
        setTokenBalance(0);
      } finally {
        setIsLoadingBalance(false);
      }
    } else {
      setTokenBalance(0);
      setIsLoadingBalance(false);
    }
  };

  // Initial balance fetch on component mount
  useEffect(() => {
    fetchBalance();

    // Refresh balance every 1 hour for passive updates
    // const interval = setInterval(fetchBalance, 3600000);
    // return () => clearInterval(interval);
  }, [account, perpsUpdate]);

  useEffect(() => {
    if (currentPrice) {
      dispatch(
        updateLiquidationPrice({
          price: currentPrice,
          leverage: Number(leverage_value),
          collateral: Number(amount),
          isLong: longShort === 0,
        })
      );
    }
  }, [currentPrice, leverage_value, amount, longShort]);

  useEffect(() => {
    if (tp && sl) {
      const currentTp = tp;
      const currentSl = sl;
      setTp(currentSl);
      setSl(currentTp);
    }
  }, [longShort]);

  const handlePercentageChange = (percentage) => {
    const newAmount = ((percentage * tokenBalance) / 100).toFixed(2);
    setAmount(newAmount);
    setAvailableAmount(percentage.toString());
  };

  const handleUSDCAmountChange = (usdcAmount) => {
    const pct = (usdcAmount / tokenBalance) * 100;
    setAmount(usdcAmount);
    setAvailableAmount(pct.toFixed(2));
  };

  const handleIncrement = () => {
    const cur = parseFloat(availableAmount) || 0;
    const next = Math.min(100, cur + 1);
    setAvailableAmount(next.toString());
    setAmount(((next * tokenBalance) / 100).toFixed(2));
  };

  const handleDecrement = () => {
    const cur = parseFloat(availableAmount) || 0;
    const next = Math.max(0, cur - 1);
    setAvailableAmount(next.toString());
    setAmount(((next * tokenBalance) / 100).toFixed(2));
  };

  const diffTP = getPnLDiff(currentPrice || openPrice, tp, longShort === 0);
  const diffSL = getPnLDiff(currentPrice || openPrice, sl, longShort === 0);
  const percentTP = getPnLPercent(
    currentPrice || openPrice,
    tp,
    longShort === 0
  );
  const percentSL = getPnLPercent(
    currentPrice || openPrice,
    sl,
    longShort === 0
  );

  const handleTpChange = (newTp) => {
    setTp(newTp);
    const base = currentPrice || parseFloat(openPrice) || 0;
    if (base > 0) {
      const diff = parseFloat(newTp) - base;
      const newPercent = (diff / base) * 100;
      setTp(newTp);
    }
  };

  const handleSlChange = (newSl) => {
    setSl(newSl);
    const base = currentPrice || parseFloat(openPrice) || 0;
    if (base > 0) {
      const diff = parseFloat(newSl) - base;
      const newPercent = (diff / base) * 100;
      setSl(newSl);
    }
  };

  const handleTpPercentChange = (percentStr) => {
    const percent = parseFloat(percentStr);
    const base = currentPrice || parseFloat(openPrice) || 0;
    if (!isNaN(percent) && base > 0) {
      // For Buy: Price increases by percentage
      // For Sell: Price decreases by percentage
      const multiplier =
        longShort === 0 ? 1 + percent / 100 : 1 - percent / 100;
      const newTp = (base * multiplier).toFixed(2);
      setTp(newTp);
    }
  };

  const handleSlPercentChange = (percentStr) => {
    const percent = parseFloat(percentStr);
    const base = currentPrice || parseFloat(openPrice) || 0;
    if (!isNaN(percent) && base > 0) {
      // For Buy: Price decreases by percentage
      // For Sell: Price increases by percentage
      const multiplier =
        longShort === 0 ? 1 - percent / 100 : 1 + percent / 100;
      const newSl = (base * multiplier).toFixed(2);
      setSl(newSl);
    }
  };

  // Update percentage input handlers
  const handleTpPercentInputChange = (value) => {
    setTpPercentInput(value);
    if (value === "") return;
    if (/^\d*\.?\d*$/.test(value)) {
      handleTpPercentChange(value);
    }
  };

  const handleSlPercentInputChange = (value) => {
    setSlPercentInput(value);
    if (value === "") return;
    if (/^\d*\.?\d*$/.test(value)) {
      handleSlPercentChange(value);
    }
  };

  // Update useEffect to also set the input values when prices change
  useEffect(() => {
    setTpPercentInput(Math.abs(percentTP).toFixed(2));
    setSlPercentInput(Math.abs(percentSL).toFixed(2));
  }, [percentTP, percentSL]);

  // Update the effect that swaps values when switching between Buy/Sell
  useEffect(() => {
    if (tp && sl) {
      const currentTp = tp;
      const currentSl = sl;
      // When switching modes, also validate the swapped values
      setTp(currentSl);
      setSl(currentTp);
    }
  }, [longShort]);

  const handleLeverageChange = (newValue) => {
    const actualValue = Math.min(100, Math.max(1, Number(newValue)));
    if (!isNaN(actualValue)) {
      setLeverageValue(actualValue.toString());

      // Always use currentPrice from Redux or fallback to local openPrice
      const price = currentPrice || openPrice;
      if (price) {
        dispatch(
          updateLiquidationPrice({
            price: price,
            leverage: actualValue,
            collateral: Number(amount) || 0,
            isLong: longShort === 0,
          })
        );
      }
    }
  };

  const handleSlippageChange = (value) => {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return;

    if (numericValue >= 0 && numericValue <= 100) {
      setSlippage(numericValue);

      if (numericValue > 5) {
        const currentTime = Date.now();
        if (currentTime - lastWarningTime >= 5000) {
          toast.warning(
            "Slippage has been set extremely high, we recommend using auto slippage for the optimal rate of slippage",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
          setLastWarningTime(currentTime);
        }
      }
    }
  };

  // Compute position size (ensure numeric conversion)
  const numericAmount = Number(amount);
  const numericLeverage = Number(leverage_value);
  const positionSize =
    !isNaN(numericAmount) && !isNaN(numericLeverage)
      ? (numericAmount * numericLeverage).toFixed(2)
      : "0.00";

  const handleOpenTrade = async () => {
    try {
      if (!account) {
        toast.error("Please connect your wallet");
        return;
      }

      const minMarginAmount = 100000;
      const depositAmount = Math.max(
        minMarginAmount,
        Number(amount) * Math.pow(10, process.env.REACT_APP_USDC_DECIMALS)
      ).toString();

      const message = {
        open_trade: {
          market_index: marketIndex, // using market config
          leverage: leverage_value.toString(),
          long: longShort === 0,
          collateral_index: process.env.REACT_APP_PERP_TOKEN_INDEXES_COLLATERAL, // using market config
          trade_type: tab === 0 ? "trade" : "limit",
          open_price:
            tab === 0 ? currentPrice.toString() : openPrice.toString(),
          tp: tp && isManualTPSL ? tp.toString() : undefined,
          sl: sl && isManualTPSL ? sl.toString() : undefined,
          slippage_p: slippage.toString(),
        },
      };

      const tokenToSend = [
        {
          amount: depositAmount,
          denom: process.env.REACT_APP_COLLATERAL_DENOM, // using market config
        },
      ];

      const res = await executePerps(
        Testnet(2).chainId,
        Testnet(2).endptTm,
        process.env.REACT_APP_PERP_SMART_CONTRACT,
        dispatch,
        message,
        account,
        "leap",
        tokenToSend
      );

      if (!res) {
        throw new Error("Transaction failed");
      }

      toast.success("Trade opened successfully!");

      return res;
    } catch (err) {
      console.error("Open trade error:", err);
      toast.error(`Failed to open trade: ${err.message}`);
      return false;
    }
  };

  // Add this to ensure liquidation price is updated whenever leverage changes
  useEffect(() => {
    if (currentPrice || openPrice) {
      const price = currentPrice || openPrice;
      dispatch(
        updateLiquidationPrice({
          price: price,
          leverage: Number(leverage_value),
          collateral: Number(amount),
          isLong: longShort === 0,
        })
      );
    }
  }, [leverage_value, amount, longShort, currentPrice, openPrice, dispatch]);

  return (
    <div className="w-max flex justify-center">
      <div className="space-y-4 w-[400px] rounded-xl bg-white p-4">
        {/* Tabs */}

        <TradeTabs tab={tab} setTab={setTab} />

        {/* Buy/Sell */}
        <TradeDirection longShort={longShort} setLongShort={setLongShort} />

        {/* Deposit Amount */}
        <DepositAmount
          amount={amount}
          setAmount={setAmount}
          tokenBalance={tokenBalance}
          setAvailableAmount={setAvailableAmount}
        />

        {/* Size Container */}
        <div className="space-y-6">
          <div className="bg-gradient-to-br from-white/90 to-white/50 backdrop-blur-xl p-3 rounded-2xl border border-white/20 shadow-[0_8px_30px_rgb(0,0,0,0.04)] transition-all duration-300 hover:shadow-[0_8px_30px_rgb(0,0,0,0.08)]">
            <div className="space-y-4">
              <div className="text-sm text-gray-600 font-medium flex items-center gap-2">
                <span>Size</span>
                <div className="h-1 w-1 rounded-full bg-[#5b1dee]/30"></div>
              </div>

              <div className="relative">
                <div className="flex items-center bg-white/80 border border-gray-100 rounded-xl shadow-sm hover:shadow-md transition-all duration-200">
                  <button
                    className="h-12 w-12 rounded-l-xl hover:bg-gray-50/80 transition-colors duration-200 flex items-center justify-center text-gray-600 hover:text-[#5b1dee]"
                    onClick={handleDecrement}
                  >
                    <div className="text-xl font-medium">-</div>
                  </button>

                  <div className="flex-1 flex items-center justify-between px-4">
                    <input
                      className="min-w-[60px] w-full font-medium text-gray-800 outline-none placeholder:text-gray-400 text-center placeholder:text-sm bg-transparent"
                      type="text"
                      inputMode="decimal"
                      value={availableAmount}
                      placeholder="0"
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val === "" || val === ".") {
                          setAvailableAmount(val);
                          setAmount("0");
                          return;
                        }
                        if (!/^\d*\.?\d*$/.test(val)) return;

                        const pct = parseFloat(val);
                        if (!isNaN(pct) && pct <= 100) {
                          handlePercentageChange(pct);
                        }
                      }}
                      onBlur={() => {
                        const parsed = parseFloat(availableAmount);
                        if (isNaN(parsed)) {
                          setAvailableAmount("0");
                          setAmount("0");
                        } else {
                          handlePercentageChange(Math.max(0, parsed));
                        }
                      }}
                    />
                    <div className="flex items-center gap-2 text-gray-600">
                      <span className="font-medium">%</span>
                    </div>
                  </div>

                  <button
                    className="h-12 w-12 hover:bg-gray-50/80 transition-colors duration-200 flex items-center justify-center text-gray-600 hover:text-[#5b1dee]"
                    onClick={handleIncrement}
                  >
                    <div className="text-xl font-medium">+</div>
                  </button>

                  <div className="h-8 w-[1px] bg-gray-200 mx-2"></div>

                  <div className="flex items-center gap-2 pr-4 text-gray-600">
                    <NUSDIcon className="w-5 h-5" />
                    <span className="font-medium">USDC</span>
                    <ArrowToDownHeader />
                  </div>
                </div>
              </div>

              <div className="space-y-1">
                <style>
                  {`
                  .rc-slider {
                    padding: 14px 0;
                  }
                  .rc-slider-track {
                    background-color: #5b1dee;
                    height: 4px;
                  }
                  .rc-slider-rail {
                    background-color: #e5e7eb;
                    height: 4px;
                  }
                  .rc-slider-handle {
                    width: 20px;
                    height: 20px;
                    border: 3px solid #5b1dee;
                    background-color: white;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                    margin-top: -8px;
                  }
                  .rc-slider-mark {
                    top: 20px;
                  }
                  .rc-slider-mark-text,
                  .rc-slider-mark-text-active {
                    margin-top: 8px;
                    color: #6B7280;
                    font-size: 12px;
                  }
                `}
                </style>
                <Slider
                  min={0}
                  max={100}
                  value={Number(availableAmount) || 0}
                  onChange={(val) => handlePercentageChange(val)}
                  step={1}
                  marks={{
                    0: "0%",
                    25: "25%",
                    50: "50%",
                    75: "75%",
                    100: "100%",
                  }}
                />
              </div>
            </div>
          </div>

          {/* Leverage Container */}
          <div className="bg-gradient-to-br from-white/90 to-white/50 backdrop-blur-xl p-3 rounded-2xl border border-white/20 shadow-[0_8px_30px_rgb(0,0,0,0.04)] transition-all duration-300 hover:shadow-[0_8px_30px_rgb(0,0,0,0.08)]">
            <div className="space-y-4">
              {/* New header with Position Size and Leverage */}
              <div className="flex justify-between items-center">
                <span className="text-sm text-gray-600 font-medium">
                  Leverage
                </span>
                {/* <span className="text-lg  text-right">{formatNumber(positionSize)}</span> */}
              </div>

              <div className="relative">
                <div className="flex items-center bg-white/80 border border-gray-100 rounded-xl shadow-sm hover:shadow-md transition-all duration-200">
                  <button
                    className="h-12 w-12 rounded-l-xl hover:bg-gray-50/80 transition-colors duration-200 flex items-center justify-center text-gray-600 hover:text-[#5b1dee]"
                    onClick={() =>
                      setLeverageValue(
                        Math.max(1, Number(leverage_value) - 1).toString()
                      )
                    }
                  >
                    <div className="text-xl font-medium">-</div>
                  </button>

                  <div className="flex-1 flex items-center justify-between px-4">
                    <input
                      className="w-full font-medium text-gray-800 outline-none placeholder:text-gray-400 text-center placeholder:text-sm bg-transparent"
                      type="text"
                      inputMode="decimal"
                      value={leverage_value}
                      placeholder="Leverage"
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val === "" || val === ".") {
                          setLeverageValue(val);
                          return;
                        }
                        const numVal = parseFloat(val);
                        if (!isNaN(numVal) && numVal >= 1 && numVal <= 100) {
                          setLeverageValue(val);
                        }
                      }}
                      onBlur={() => {
                        const parsed = parseFloat(leverage_value);
                        if (isNaN(parsed)) {
                          setLeverageValue("1.00");
                        } else {
                          setLeverageValue(
                            Math.min(100, Math.max(1, parsed)).toFixed(2)
                          );
                        }
                      }}
                    />
                    <div className="flex items-center gap-2 text-gray-600">
                      <span className="font-medium">x</span>
                    </div>
                  </div>

                  <button
                    className="h-12 w-12 hover:bg-gray-50/80 transition-colors duration-200 flex items-center justify-center text-gray-600 hover:text-[#5b1dee]"
                    onClick={() =>
                      setLeverageValue(
                        Math.min(100, Number(leverage_value) + 1).toString()
                      )
                    }
                  >
                    <div className="text-xl font-medium">+</div>
                  </button>
                </div>
              </div>

              <div className="space-y-2">
                <style>
                  {`
                    .rc-slider {
                      padding: 14px 0;
                    }
                    .rc-slider-track {
                      background-color: #5b1dee;
                      height: 4px;
                    }
                    .rc-slider-rail {
                      background-color: #e5e7eb;
                      height: 4px;
                    }
                    .rc-slider-handle {
                      width: 20px;
                      height: 20px;
                      border: 3px solid #5b1dee;
                      background-color: white;
                      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                      margin-top: -8px;
                    }
                    .rc-slider-dot {
                      width: 2px;
                      height: 14px;
                      bottom: -6px;
                      background-color: #e5e7eb;
                      border: 0;
                      border-radius: 0;
                    }
                    .rc-slider-dot-active {
                      background-color: #5b1dee;
                    }
                    .rc-slider-mark-text,
                    .rc-slider-mark-text-active {
                      margin-top: 5px;
                      color: #6B7280;
                      font-size: 12px;
                    }
                  `}
                </style>
                <Slider
                  min={1}
                  max={100}
                  value={Number(leverage_value) || 1}
                  onChange={handleLeverageChange}
                  step={1}
                  marks={{
                    1: "1x",
                    25: "25x",
                    50: "50x",
                    75: "75x",
                    100: "100x",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-white/90 to-white/50 backdrop-blur-xl p-3 rounded-2xl border border-white/20 shadow-[0_8px_30px_rgb(0,0,0,0.04)] transition-all duration-300">
          <div className="space-y-2">
            {/* Liquidation Price - now first */}
            <div className="flex justify-between items-center">
              <div className="text-sm text-gray-600">Liquidation Price</div>
              <div className="font-medium">
                <span
                  className={`${
                    liquidationPrice > 0 ? "text-[#EB4245]" : "text-gray-400"
                  }`}
                >
                  ${formatNumber(liquidationPrice)}
                </span>
              </div>
            </div>

            {/* Current Price - now second */}
            <div className="flex justify-between items-center">
              <div className="text-sm text-gray-600">Current Price</div>
              <div className="font-medium">
                <span className="text-[#5DBE89]">
                  ${formatNumber(currentPrice)}
                </span>
              </div>
            </div>

            {/* Position Size - remains third */}
            <div className="flex justify-between items-center">
              <div className="text-sm text-gray-600">Position Size</div>
              <div className="font-medium">
                <span className="text-gray-800">
                  ${formatNumber(positionSize)}
                </span>
              </div>
            </div>
          </div>
        </div>
        {tab !== 0 && (
          <div className="flex justify-between items-center mb-4">
            <label className="text-gray-700 text-sm font-medium">
              Open Price:
            </label>
            <input
              type="number"
              value={openPrice}
              onChange={(e) => setOpenPrice(e.target.value)}
              className="w-full sm:w-40 border border-gray-300 rounded-md p-2 text-right"
              placeholder="Enter open price"
              step="0.01"
              min="0"
            />
          </div>
        )}

        <TPSLSection
          isManualTPSL={isManualTPSL}
          setIsManualTPSL={setIsManualTPSL}
          longShort={longShort}
          tp={tp}
          sl={sl}
          tpPercentInput={tpPercentInput}
          slPercentInput={slPercentInput}
          handleTpChange={handleTpChange}
          handleSlChange={handleSlChange}
          handleTpPercentInputChange={handleTpPercentInputChange}
          handleSlPercentInputChange={handleSlPercentInputChange}
          percentTP={getPnLPercent(
            currentPrice || openPrice,
            tp,
            longShort === 0
          )}
          percentSL={getPnLPercent(
            currentPrice || openPrice,
            sl,
            longShort === 0
          )}
          TP_EST={
            longShort === 0
              ? (Number(tp) - Number(tab === 0 ? currentPrice : openPrice)) *
                Number(leverage_value)
              : (Number(tab === 0 ? currentPrice : openPrice) - Number(tp)) *
                Number(leverage_value)
          }
          SL_EST={
            longShort === 0
              ? (Number(tab === 0 ? currentPrice : openPrice) - Number(sl)) *
                Number(leverage_value)
              : (Number(sl) - Number(tab === 0 ? currentPrice : openPrice)) *
                Number(leverage_value)
          }
        />

        <button
          onClick={async () => {
            console.log(`Submitting trade with:
              Mode: ${longShort === 0 ? "BUY" : "SELL"}
              Type: ${tab === 0 ? "Market" : "Limit"}
              Take Profit: ${tp}
              Stop Loss: ${sl}
              Current Price: ${currentPrice || openPrice}
            `);

            // Use the new handler to execute trades and refresh balance after
            // if (longShort === 0) {
            //   if (tab === 0) {
            //     await handleTradeExecution(handleLongOpenTrade);
            //   } else {
            //     await handleTradeExecution(handleLongLimitTrade, limitPrice);
            //   }
            // } else {
            //   if (tab === 0) {
            //     await handleTradeExecution(handleShortOpenTrade);
            //   } else {
            //     await handleTradeExecution(handleShortLimitTrade, limitPrice);
            //   }
            // }
            await handleOpenTrade();
          }}
          className={`w-full py-3 px-4 ${
            longShort === 0 ? "bg-green-500" : "bg-red-500"
          } text-white rounded-lg font-medium transition-colors`}
        >
          {`${tab === 0 ? "Market" : "Limit"} ${
            longShort === 0 ? "Buy" : "Sell"
          }`}
        </button>

        {/* Slippage */}
        <div className="space-y-3">
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-600">Slippage Tolerance</span>
            <button
              onClick={() => setIsAutoSlippage(!isAutoSlippage)}
              className={`px-3 py-1 text-sm rounded-md transition-colors ${
                isAutoSlippage
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
            >
              Auto
            </button>
          </div>

          {/* Only show manual controls when auto is off */}
          {!isAutoSlippage && (
            <div className="space-y-3">
              <div className="flex gap-2">
                {slippagePresets.map((preset) => (
                  <button
                    key={preset.value}
                    onClick={() => handleSlippageChange(preset.value)}
                    className={`px-3 py-1 text-sm rounded-md transition-colors ${
                      slippage === preset.value
                        ? "bg-blue-500 text-white"
                        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    }`}
                  >
                    {preset.label}
                  </button>
                ))}
              </div>

              <div className="flex items-center gap-2">
                <div className="relative flex-1">
                  <input
                    type="number"
                    value={slippage}
                    onChange={(e) => handleSlippageChange(e.target.value)}
                    min="0"
                    max="100"
                    step="0.1"
                    className={`w-full px-3 py-2 pr-8 border rounded-md focus:outline-none focus:ring-1 ${
                      slippage > 5
                        ? "border-yellow-400 focus:ring-yellow-400"
                        : "border-gray-300 focus:ring-purple-600"
                    }`}
                  />
                  <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">
                    %
                  </span>
                </div>
              </div>

              {slippage > 5 && (
                <div className="text-sm text-yellow-600 bg-yellow-50 p-2 rounded-md">
                  High slippage tolerance may result in unfavorable trade
                  execution
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TradingForm;
