import btcIcon from "../../../../../assets/images/Perps/bitcoin-btc-logo.png";

import { useNavigate, useLocation } from "react-router-dom";

export const BTCCard = () => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate("/perps/markets/crypto/btc")}
      className="bg-white w-[300px] cursor-pointer p-[24px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
    >
      <div className="flex items-center gap-2">
        <div className="text-[24px]">Bitcoin</div>
        <img src={btcIcon} className="w-[24px]"></img>
      </div>
    </div>
  );
};
