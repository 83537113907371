import React from 'react'

function Building() {
  return (
    <div>  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.668 7.50001V3.33409C11.668 3.06078 11.802 2.80484 12.0266 2.64916C12.2513 2.49348 12.538 2.45786 12.7939 2.55383L16.9606 4.11633C17.2858 4.23829 17.5013 4.54922 17.5013 4.89659V17.5"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66797 17.5V8.50154C6.66797 7.94841 7.11637 7.5 7.6695 7.5H13.1664C13.432 7.5 13.6868 7.60552 13.8746 7.79334C14.0624 7.98117 14.168 8.23591 14.168 8.50154V17.5"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.16797 7.49999V4.99999C9.16797 4.53975 8.79487 4.16666 8.33464 4.16666H5.0013C4.54106 4.16666 4.16797 4.53975 4.16797 4.99999V9.99999"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66667 2.5V4.16667"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3346 17.5H1.66797"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 17.5V10.8333C2.5 10.3731 2.8731 10 3.33333 10H6.66667"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.16667 14.5833V10.4167"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6667 14.5833V10.4167"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg></div>
  )
}

export default Building