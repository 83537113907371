import React from "react";
import { Parking } from "../../Amenities/Parking";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";

const PARKING_FACILITIES_AMENITIES = [
    { id: 'parking', Icon: Parking }
  ];

  
const ParkingAndFacilities = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
      const currentAmenities = metaDetails.amenities["Parking and facilities"] || [];
      const isSelected = currentAmenities.includes(amenityId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          "Parking and facilities": isSelected
            ? currentAmenities.filter(item => item !== amenityId)
            : [...currentAmenities, amenityId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {PARKING_FACILITIES_AMENITIES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities["Parking and facilities"]?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleAmenity(id)} />
            ) : (
              <PlusIcon onClick={() => toggleAmenity(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default ParkingAndFacilities;