import React from "react";
import { Bathub,HairDryer,Shower,HotWater,SoapDrop,Heating,ToiletSeat,BathHanger } from "../../Amenities/Bathroom";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";

const BATHROOM_AMENITIES = [
    { id: 'bathub', Icon: Bathub },
    { id: 'hairdryer', Icon: HairDryer },
    { id: 'shower', Icon: Shower },
    { id: 'hotwater', Icon: HotWater },
    { id: 'soapdrop', Icon: SoapDrop },
    { id: 'heating', Icon: Heating },
    { id: 'toiletseat', Icon: ToiletSeat },
    { id: 'bathhanger', Icon: BathHanger },
  ];
  
  const BathroomAmenities = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
      const currentAmenities = metaDetails.amenities.Bathroom || [];
      const isSelected = currentAmenities.includes(amenityId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          Bathroom: isSelected
            ? currentAmenities.filter(item => item !== amenityId)
            : [...currentAmenities, amenityId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {BATHROOM_AMENITIES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities.Bathroom?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleAmenity(id)} />
            ) : (
              <PlusIcon onClick={() => toggleAmenity(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default BathroomAmenities;
  