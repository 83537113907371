import { useLocation } from "react-router-dom";
// import EtheriumTradingView from "../../../Perps/TradingView/EtheriumTradingView";
// import NibruTradingView from "../../../Perps/TradingView/NibruTradingView";
// import TradingChartWidget from "../../../../Components/Trading/TradingChartWidget";
// import TradingViewWidget from "../../../Perps/TradingView/TradingViewWidget";
// import DubaiPriceChart from "../../../Perps/TradingView/DubaiTradingView";
import EtheriumTradingView from "./Charts/EtheriumTradingView";
import DubaiPriceChart from "./Charts/DubaiTradingView";
import BtcTradingView from "./Charts/BtcTradingView";
import AtomTradingView from "./Charts/AtomTradingView";

const TradingChart = ({ chartType }) => {
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const chartType = searchParams.get("chart")?.toLowerCase();

  const renderChart = () => {
    switch (chartType) {
      case "ethereum":
        return <EtheriumTradingView />;
      case "atom":
        return <AtomTradingView />;
      case "bitcoin":
        return <BtcTradingView />;
      default:
        return <DubaiPriceChart />;
    }
  };

  return (
    <div className="mt-4 bg-white w-full p-[10px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
      <div className="w-full flex justify-end" />
      {renderChart()}
    </div>
  );
};

export default TradingChart;
