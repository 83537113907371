import React, { useEffect, useRef, useState, memo } from "react"
import Arrow from "../../../Components/Trading/Arrow"
import { ChartTypeToggle } from "./ChartView"
const intervals = [
  { value: "5S", label: "5s" },
  { value: "15S", label: "15s" },
  { value: "30S", label: "30s" },
  { value: "1", label: "1m" },
  { value: "3", label: "3m" },
  { value: "5", label: "5m" },
  { value: "15", label: "15m" },
  { value: "30", label: "30m" },
  { value: "1H", label: "1h" },
  { value: "2H", label: "2h" },
  { value: "4H", label: "4h" },
  { value: "12H", label: "12h" },
  { value: "D", label: "1d" },
  { value: "3D", label: "3d" },
  { value: "W", label: "1w" },
  { value: "M", label: "1M" },
]

function BtcTradingView() {
  const container = useRef(null)
  const widgetRef = useRef(null)
  const [interval, setInterval] = useState("15S")
  const [isOpen, setIsOpen] = useState(false)
  const [chartType, setChartType] = useState('line')

  useEffect(() => {
    if (!container.current || widgetRef.current) return

    const createWidget = () => {
      const script = document.createElement("script")
      script.src = "https://s3.tradingview.com/tv.js"
      script.async = true
      script.onload = () => {
        if (typeof window.TradingView !== "undefined") {
          widgetRef.current = new window.TradingView.widget({
            container_id: "tv-chart-container",
            autosize: true,
            symbol: "BITSTAMP:BTCUSD",
            interval: interval,
            timezone: "Etc/UTC",
            theme: "light",
            style: chartType === 'line' ? "1" : "3",
            locale: "en",
            hide_legend: true,
            hide_top_toolbar: true,
            allow_symbol_change: true,
            height: "500",
            save_image: false,
            enabled_features: [],
            disabled_features: [],
          })
        }
      }
      document.head.appendChild(script)
    }

    createWidget()

    return () => {
      if (widgetRef.current) {
        widgetRef.current = null
      }
    }
  }, [interval, chartType])

  const handleIntervalChange = (newInterval) => {
    setInterval(newInterval)
    setIsOpen(false)
  }

  return (
    <div className="w-full relative">
      <div className="flex justify-between mb-2 relative z-10">
        <ChartTypeToggle activeType={chartType} onToggle={setChartType} setChartType={setChartType} />
        <button
          onClick={() => setIsOpen(!isOpen)}
          style={{
            border: "1px solid #E3E3E3",
            boxShadow:
              "0px 0px 0px 2px #0000000D, -2px -2px 6px 0px #FDFFFFCC, 2px 2px 6px 0px #BBC3CE99",
          }}
          className="px-3 py-1.5 bg-white rounded-md hover:bg-gray-50 transition-all duration-200 focus:outline-none text-sm font-medium text-gray-700"
        >
          {intervals.find((i) => i.value === interval)?.label || "15s"}
        </button>

        <div
          className={`absolute right-0 mt-2 w-20 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition-all duration-200 ${
            isOpen ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2 pointer-events-none"
          }`}
        >
          <div
            className="py-1 max-h-[240px] overflow-y-auto custom-scrollbar"
            role="menu"
          >
            {intervals.map((int) => (
              <button
                key={int.value}
                onClick={() => handleIntervalChange(int.value)}
                className={`w-full text-left px-3 py-1 text-sm transition-colors duration-150 ${
                  interval === int.value ? "text-blue-600 bg-blue-50" : "text-gray-700 hover:bg-gray-50"
                }`}
                role="menuitem"
              >
                {int.label}
              </button>
            ))}
          </div>
        </div>
      </div>

      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: transparent;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #E5E7EB;
          border-radius: 20px;
        }
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #E5E7EB transparent;
        }
      `}</style>

      <div
        id="tv-chart-container"
        ref={container}
        className="tradingview-widget-container h-[500px] w-full"
      />
    </div>
  )
}

export default memo(BtcTradingView)