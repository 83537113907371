import React from 'react'

function Rental() {
  return (
    <div>  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 14.1667H15C16.3807 14.1667 17.5 13.0474 17.5 11.6667V5.83334C17.5 4.45263 16.3807 3.33334 15 3.33334H5C3.61929 3.33334 2.5 4.45263 2.5 5.83334V7.50001"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.52734 7.69355C9.05365 7.16725 9.82075 6.96171 10.5397 7.15435C11.2586 7.34699 11.8202 7.90855 12.0128 8.62749C12.2055 9.34643 11.9999 10.1135 11.4736 10.6398"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 16.6667L3.46293 17.1481C3.92577 17.3795 4.43613 17.5 4.9536 17.5H10.4167C11.107 17.5 11.6667 16.9404 11.6667 16.25V16.25C11.6667 15.5596 11.107 15 10.4167 15H7.08333"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 10.8333H4.92779C5.28841 10.8333 5.6393 10.9503 5.92779 11.1667L7.79218 12.565C8.10591 12.8003 8.3021 13.1601 8.3299 13.5513C8.3577 13.9425 8.21438 14.3265 7.93708 14.6038V14.6038C7.46104 15.0798 6.70741 15.1334 6.16883 14.7294L5 13.8528"
      stroke="#5A5A5A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg></div>
  )
}

export default Rental