import { AmenityIcons } from "./AmenityIcons";

export const AmenitiesSection = ({ metaDetails }) => {
  return (
    <div className="w-full space-y-[10px]">
      <div className="font-semibold text-[18px]">Amenities</div>
      <div className="grid grid-cols-2 gap-[8px] text-[15px] font-normal">
        {Object.entries(metaDetails.amenities || {})?.map((category) => (
          <div key={category[0]}>
            {category[1].map((item) => (
              <AmenityIcons key={item} item={item} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}