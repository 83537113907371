import { useEffect, useState, useCallback } from "react";
import { queryContract } from "../functions/Contract";
import { Testnet } from "@nibiruchain/nibijs";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPrice } from "../../ReduxSlices/TradeSlice";

const formatNumber = (num) => {
  if (num === undefined || num === null || isNaN(Number(num))) return "0.00";
  return Number(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const TradingTopBar = ({ tokenIndex, marketName = "Asset" }) => {
  const price = useSelector((state) => state.trade.currentPrice);
  const { liquidationPrice } = useSelector((state) => state.liquidation);
  const [marketData, setMarketData] = useState({
    price: 0,
    change24h: 0,
    volume24h: "$0M",
  });

  const dispatch = useDispatch();

  const getPrice = useCallback(async () => {
    const msg = {
      get_price: {
        index: tokenIndex,
      },
    };
    try {
      const res = await queryContract(
        process.env.REACT_APP_ORACLE_SMART_CONTRACT,
        msg,
        Testnet(2).endptTm
      );
      dispatch(setCurrentPrice(res.price));
    } catch (error) {
      console.error("Failed to fetch price:", error);
    }
  }, [tokenIndex, dispatch]);

  const getCoinId = useCallback((index) => {
    console.log("Getting coin ID for index:", index);
    switch (index) {
      case "1":
      case 1:
        return "bitcoin";
      case "2":
      case 2:
        return "ethereum";
      case "3":
      case 3:
        return "cosmos";
      case 5:
        return "dubai"
      default:
        console.log("Default case hit for index:", index);
        return "0$";
    }
  }, []);

  const fetchDubaiMarketData = useCallback(async () => {
    try {
      console.log("Fetching Dubai market data...");
      const module = await import("../../Components/functions/Apiwithouttoken");
      const api = module.api || module.default?.api;
      
      if (!api) {
        console.error("API function not found in imported module");
        return;
      }
      
      const response = await api("parcl/data");
      console.log("Dubai API response:", response);
      
      if (response && response.success && response.data) {
        const { metrics, PriceData } = response.data;
        
        const sortedPriceData = [...PriceData].sort((a, b) => 
          new Date(b.date) - new Date(a.date)
        );
        
        const todayPrice = sortedPriceData[0]?.price || 0;
        const yesterdayPrice = sortedPriceData[1]?.price || todayPrice;
        
        console.log("Price data points:", {
          today: todayPrice,
          yesterday: yesterdayPrice
        });
        
        // Calculate 24h change percentage
        const change24h = yesterdayPrice > 0 
          ? ((todayPrice - yesterdayPrice) / yesterdayPrice) * 100 
          : 0;
        
        let formattedVolume = "$0";
        if (metrics && (metrics.volume || metrics.volume24h)) {
          const volumeValue = metrics.volume24h || metrics.volume || "132080";
          
          const cleanedVolume = String(volumeValue).replace(/[$,]/g, '');
          const volumeNum = parseFloat(cleanedVolume);
          
          if (!isNaN(volumeNum)) {
            if (volumeNum >= 1e9) {
              formattedVolume = `$${(volumeNum / 1e9).toFixed(2)}B`;
            } else if (volumeNum >= 1e6) {
              formattedVolume = `$${(volumeNum / 1e6).toFixed(2)}M`;
            } else if (volumeNum >= 1e3) {
              formattedVolume = `$${(volumeNum / 1e3).toFixed(2)}K`;
            } else {
              formattedVolume = `$${volumeNum.toFixed(2)}`;
            }
          }
        }
        
        console.log("Dubai market data processed:", {
          price: todayPrice,
          change24h: change24h,
          volume24h: formattedVolume,
        });
        
        setMarketData({
          price: todayPrice,
          change24h: change24h,
          volume24h: formattedVolume,
        });
      }
    } catch (error) {
      console.error("Error fetching Dubai data:", error);
      setMarketData({
        price: 0,
        change24h: 0,
        volume24h: "$0",
      });
    }
  }, []);

  const getMarketData = useCallback(async () => {
    try {
      console.log("Getting market data for tokenIndex:", tokenIndex);
      const coinId = getCoinId(tokenIndex);
      console.log("Identified coin ID:", coinId);
      
      if (coinId === "dubai") {
        console.log("Using custom API for Dubai");
        await fetchDubaiMarketData();
        return;
      }
      
      console.log(`Fetching data from CoinGecko for ${coinId}`);
      const apiUrl = `https://api.coingecko.com/api/v3/coins/${coinId}`;
      
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      
      const data = await response.json();
      console.log(`CoinGecko data received for ${coinId}:`, data);

      const currentPrice = data.market_data?.current_price?.usd || 0;
      const priceChange24h = data.market_data?.price_change_percentage_24h || 0;
      const volume24h = data.market_data?.total_volume?.usd 
        ? `$${(data.market_data.total_volume.usd / 1000000).toFixed(2)}M` 
        : "$0M";
      
      console.log("Processed market data:", {
        price: currentPrice,
        change24h: priceChange24h,
        volume24h: volume24h
      });
      
      setMarketData({
        price: currentPrice,
        change24h: priceChange24h,
        volume24h: volume24h
      });
    } catch (error) {
      console.error("Error fetching market data:", error);
      setMarketData({
        price: 0,
        change24h: 0,
        volume24h: "$0M"
      });
    }
  }, [tokenIndex, getCoinId, fetchDubaiMarketData]);

  useEffect(() => {
    console.log("TradingTopBar useEffect triggered with tokenIndex:", tokenIndex);

    setMarketData({
      price: 0,
      change24h: 0,
      volume24h: "$0M",
    });

    getPrice();
    getMarketData();

    const priceInterval = setInterval(getPrice, 10000);
    const marketDataInterval = setInterval(getMarketData, 300000);
    
    return () => {
      clearInterval(priceInterval);
      clearInterval(marketDataInterval);
    };
  }, [tokenIndex, getPrice, getMarketData]);

  return (
    <div className="w-full bg-white mt-3 p-[16px] rounded-[8px] shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]">
      <div className="flex gap-[20px]">
        <table className="w-full">
          <thead>
            <tr className="w-full text-[#8A8A8A] text-[14px]">
              <td className="w-1/3">PRICE</td>
              <td className="w-1/3">24H CHANGE</td>
              <td className="w-1/3">24H VOLUME</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="text-[#5DBE89]">
                  ${formatNumber(price)}
                </div>
              </td>
              <td>
                <span className={marketData.change24h >= 0 ? "text-[#5DBE89]" : "text-[#EB4245]"}>
                  {formatNumber(marketData.change24h)}%
                </span>
              </td>
              <td>{marketData.volume24h}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};