import React from "react";
import { Kitchen,ForkKnife,Stove,Induction,Dishes,Fridge,TableChair,CoffeeMachine,Blender,SaltPepper,DishWasher,WineGlass,Trash,PlateFork } from "../../Amenities/Kitchen";
import { CheckMarkPurple,PlusIcon } from "../../../AssetComponents/Images";
const KITCHEN_DINING_AMENITIES = [
    { id: 'kitchen', Icon: Kitchen },
    { id: 'forkknife', Icon: ForkKnife },
    { id: 'stove', Icon: Stove },
    { id: 'induction', Icon: Induction },
    { id: 'dishes', Icon: Dishes },
    { id: 'fridge', Icon: Fridge },
    { id: 'tablechair', Icon: TableChair },
    { id: 'coffeemachine', Icon: CoffeeMachine },
    { id: 'blender', Icon: Blender },
    { id: 'saltpepper', Icon: SaltPepper },
    { id: 'dishwasher', Icon: DishWasher },
    { id: 'wineglass', Icon: WineGlass },
    { id: 'trash', Icon: Trash },
    { id: 'platefork', Icon: PlateFork }
  ];

  
const KitchenAndDining = ({ metaDetails, setMetaDetails }) => {
    const toggleAmenity = (amenityId) => {
      const currentAmenities = metaDetails.amenities["Kitchen and dining"] || [];
      const isSelected = currentAmenities.includes(amenityId);
      
      setMetaDetails({
        ...metaDetails,
        amenities: {
          ...metaDetails.amenities,
          "Kitchen and dining": isSelected
            ? currentAmenities.filter(item => item !== amenityId)
            : [...currentAmenities, amenityId]
        }
      });
    };
  
    return (
      <div className="grid grid-cols-2 gap-[10px]">
        {KITCHEN_DINING_AMENITIES.map(({ id, Icon }) => (
          <div key={id} className="w-full items-center justify-between flex">
            <Icon />
            {metaDetails.amenities["Kitchen and dining"]?.includes(id) ? (
              <CheckMarkPurple onClick={() => toggleAmenity(id)} />
            ) : (
              <PlusIcon onClick={() => toggleAmenity(id)} />
            )}
          </div>
        ))}
      </div>
    );
  };
  
  export default KitchenAndDining;