import React from "react";
import { ArrowToLeft } from "../../AssetComponents/Images";
import { useNavigate } from "react-router-dom";

const TradingHeader = () => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(-1)}
      className="w-full cursor-pointer flex gap-2 rounded-[8px] px-2 py-3 hover:bg-[#f6f6f6] bg-white shadow-[2px_2px_6px_0px_rgba(187,195,206,0.6),-2px_-2px_6px_0px_rgba(253,255,255,0.8)]"
    >
      <ArrowToLeft />
      <div>Back to Market Page</div>
    </div>
  );
};

export default TradingHeader;
